import React, { Fragment } from 'react';
import Loadable from 'react-loadable';
import Route from 'react-router/Route';
import Switch from 'react-router/Switch';

import { Breadcrumb } from 'containers/Breadcrumb';
import NotFound from 'components/NotFound';
import { WithAuth } from 'enhance';
import {
  CATALOG,
  NEW_CUSTOMER,
  CUSTOMER,
  CUSTOMERS,
  DASHBOARD,
  HOME,
  LOGIN,
  NEW_OFFER,
  OFFER,
  NEW_ORDER,
  ORDER,
  ORDERS_OFFERS,
  NEW_VISIT,
  VISIT,
  VISITS,
  PRODUCT,
  PRODUCTS,
} from './componentMap';

const Loading = ({ error, pastDelay }) => {
  if (error) {
    return <NotFound>Noe gikk galt...</NotFound>;
  } else if (pastDelay) {
    return <NotFound>Loading...</NotFound>;
  } else return null;
};

const loadableComponents = {
  [HOME]: Loadable({
    loader: () =>
      import('../containers/Home' /* webpackChunkName: "container-Home" */),
    loading: Loading,
  }),
  [CATALOG]: Loadable({
    loader: () =>
      import('../containers/Categories' /* webpackChunkName: "container-Categories" */),
    loading: Loading,
  }),
  [CATALOG]: Loadable({
    loader: () =>
      import('../containers/Categories' /* webpackChunkName: "container-Categories" */),
    loading: Loading,
  }),
  [LOGIN]: Loadable({
    loader: () =>
      import('../containers/Login' /* webpackChunkName: "container-Login" */),
    loading: Loading,
  }),
  [DASHBOARD]: Loadable({
    loader: () =>
      import('../containers/Dashboard' /* webpackChunkName: "container-Dashboard" */),
    loading: Loading,
  }),
  [PRODUCTS]: Loadable({
    loader: () =>
      import('../containers/Products' /* webpackChunkName: "container-Products" */),
    loading: Loading,
  }),
  [PRODUCT]: Loadable({
    loader: () =>
      import('../containers/Product' /* webpackChunkName: "container-Product" */),
    loading: Loading,
  }),
  [CUSTOMER]: Loadable({
    loader: () =>
      import('../containers/Customer' /* webpackChunkName: "container-Customer" */),
    loading: Loading,
  }),
  [NEW_CUSTOMER]: Loadable({
    loader: () =>
      import('../containers/NewCustomer' /* webpackChunkName: "container-NewCustomer" */),
    loading: Loading,
  }),
  [CUSTOMERS]: Loadable({
    loader: () =>
      import('../containers/Customers' /* webpackChunkName: "container-Customers" */),
    loading: Loading,
  }),
  [ORDERS_OFFERS]: Loadable({
    loader: () =>
      import('../components/OrdersOffers' /* webpackChunkName: "container-OrdersOffers" */),
    loading: Loading,
  }),
  [ORDER]: Loadable({
    loader: () =>
      import('../containers/Order' /* webpackChunkName: "container-Order" */),
    loading: Loading,
  }),
  [NEW_ORDER]: Loadable({
    loader: () =>
      import('../containers/NewOrder' /* webpackChunkName: "container-NewOrder" */),
    loading: Loading,
  }),
  [OFFER]: Loadable({
    loader: () =>
      import('../containers/Offer' /* webpackChunkName: "container-Offer" */),
    loading: Loading,
  }),
  [NEW_OFFER]: Loadable({
    loader: () =>
      import('../containers/NewOffer' /* webpackChunkName: "container-NewOffer" */),
    loading: Loading,
  }),
  [VISITS]: Loadable({
    loader: () =>
      import('../containers/Visits' /* webpackChunkName: "container-Visits" */),
    loading: Loading,
  }),
  [VISIT]: Loadable({
    loader: () =>
      import('../containers/Visit' /* webpackChunkName: "container-Visit" */),
    loading: Loading,
  }),
  [NEW_VISIT]: Loadable({
    loader: () =>
      import('../containers/NewVisit' /* webpackChunkName: "container-NewVisit" */),
    loading: Loading,
  }),
};

const renderRoutes = (routes, switchProps = {}) =>
  routes ? (
    <Switch {...switchProps || {}}>
      {routes.map((route, idx) => {
        const getComponent = (route, props) => {
          const componentProps = { ...props, route };
          const LoadableComponent = loadableComponents[route.type];

          return (
            <Fragment>
              {props.match.isExact && !route.noBreadcrumb ? (
                <Breadcrumb items={route.crumbs || []} />
              ) : null}

              {route.auth ? (
                <WithAuth
                  render={() => <LoadableComponent {...componentProps} />}
                />
              ) : (
                <LoadableComponent {...componentProps} />
              )}
            </Fragment>
          );
        };
        return (
          <Route
            key={route.key || idx}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            render={props => getComponent(route, props)}
          />
        );
      })}
    </Switch>
  ) : null;

export default renderRoutes;
