import {
  CONTENT_TREE_REQUEST,
  CONTENT_TREE_SUCCESS,
  CONTENT_TREE_FAILURE,
} from 'actions';

const initialState = {
  tree:
    (window.globalConfiguration &&
      JSON.parse(window.globalConfiguration.routes)) ||
    null,
  isFetching: false,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case CONTENT_TREE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case CONTENT_TREE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        tree: action.payload.contentTree,
      };
    case CONTENT_TREE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
