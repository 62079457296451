import { CALL_API } from 'common/Api';
import { replace } from 'react-router-redux';

import { navigateCategory } from './navigate';

export const CATEGORIES_OPEN_CATEGORY = 'CATEGORIES_OPEN_CATEGORY';
export const openCategory = categoryCode => dispatch => {
  dispatch(navigateCategory(categoryCode, true));

  return dispatch({
    type: CATEGORIES_OPEN_CATEGORY,
    payload: categoryCode,
  });
};

export const CATEGORIES_OPEN_SUB_CATEGORY = 'CATEGORIES_OPEN_SUB_CATEGORY';

export const openSubCategory = categoryCode => dispatch => {
  dispatch(replace(`/produkter/${categoryCode}`));

  return dispatch({
    type: CATEGORIES_OPEN_SUB_CATEGORY,
    payload: categoryCode,
  });
};

export const CATEGORIES_CLOSE_ALL = 'CATEGORIES_CLOSE_ALL';

export const closeAllCategories = () => ({
  type: CATEGORIES_CLOSE_ALL,
});

export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST';
export const CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS';
export const CATEGORIES_FAILURE = 'CATEGORIES_FAILURE';

const fetchCategories = () => ({
  [CALL_API]: {
    types: [CATEGORIES_REQUEST, CATEGORIES_SUCCESS, CATEGORIES_FAILURE],
    path: 'categories-api/Categories',
  },
});

export const getCategories = () => (dispatch, getStore) => {
  if (Object.keys(getStore().categories.items).length > 0) {
    return Promise.resolve(dispatch(closeAllCategories()));
  }

  return dispatch(fetchCategories());
};
