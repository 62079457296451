import {
  CLEAR_PRODUCT_STORAGE,
  PRODUCT_SUCCESS,
  PRODUCT_REQUEST,
  PRODUCT_FAILURE,
  SET_ACTIVE_VARIANT,
  PRODUCT_PAGE_STOCK_REQUEST,
  PRODUCT_PAGE_STOCK_SUCCESS,
  PRODUCT_PAGE_STOCK_FAILURE,
  PRODUCT_VARIANT_STOCK_REQUEST,
  PRODUCT_VARIANT_STOCK_SUCCESS,
  PRODUCT_VARIANT_STOCK_FAILURE,
} from 'actions';

const initialState = {
  data: {},
  details: {},
  error: null,
  isFetching: false,
  isFetchingStock: false,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_REQUEST:
      return {
        ...state,
        data: {},
        isFetching: true,
      };

    case PRODUCT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };

    case PRODUCT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case SET_ACTIVE_VARIANT: {
      const {
        technicalSpecification: variantSpecification = [],
      } = action.payload;

      const { technicalSpecification: productSpecification = [] } = state.data;

      const technicalSpecification = [
        ...variantSpecification,
        ...productSpecification,
      ];
	  
	  const description = state.data.description;
      const shortDescription = state.data.shortDescription;
      const productNumber = action.payload.productNumber;
      const documents = state.data.documents;
      documents.push(state.data.documents.filter(a => !documents.some(d => d.url == a.url)));
      documents.push(action.payload.documents.filter(a => !documents.some(d => d.url == a.url)));

      const media =
        action.payload.media.length > 0
          ? action.payload.media
          : state.data.media;

      return {
        ...state,
        details: {
          ...state.data,
          ...action.payload,
          media,
          technicalSpecification,
		  description,
		  shortDescription,
		  productNumber,
          documents
        },
      };
    }

    case PRODUCT_PAGE_STOCK_REQUEST:
      return {
        ...state,
        isFetchingStock: true,
      };

    case PRODUCT_PAGE_STOCK_SUCCESS:
      const productStockPayload = action.payload[0];

      return {
        ...state,
        isFetchingStock: false,
        data: {
          ...state.data,
          priceFrom: productStockPayload.price,
        }
      };

    case PRODUCT_PAGE_STOCK_FAILURE:
      return {
        ...state,
        isFetchingStock: false,
        error: action.error,
      };

    case PRODUCT_VARIANT_STOCK_REQUEST:
      return {
        ...state,
        isFetchingStock: true,
      };

    case PRODUCT_VARIANT_STOCK_SUCCESS:
      const stockPayload = action.payload[0];
      return {
        ...state,
        isFetchingStock: false,
        details: {
          ...state.details,
          confirmedDeliveryDate: stockPayload.confirmedDeliveryDate,
          maxQuantity: stockPayload.maxQuantity,
          minQuantity: stockPayload.minQuantity,
          price: stockPayload.price,
          step: stockPayload.step,
          stock: stockPayload.stock,
          stockLabel: stockPayload.stockLabel,
          unit: stockPayload.unit,
        },
      };

    case PRODUCT_VARIANT_STOCK_FAILURE:
      return {
        ...state,
        isFetchingStock: false,
        error: action.error,
      };

    case CLEAR_PRODUCT_STORAGE:
      return initialState;

    default:
      return state;
  }
}
