import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';
import Notifications from 'containers/Notifications';

import { GlobalStyles } from 'common/theme';
import Activity from 'containers/Activity';
import Header from 'components/Header';
import Routing from 'routing/Routing';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  justify-content: start;
  align-items: center;
`;

class App extends Component {
  render() {
    const app = (
      <Fragment>
      <GlobalStyles />
        <Header />
        <StyledWrapper>
          <Activity />
          <Routing />
          <Notifications />
        </StyledWrapper>
      </Fragment>
    );

    const loader = <Fragment>loading...</Fragment>;

    return this.props.contentTree === null ? loader : app;
  }
}

App.propTypes = {
  contentTree: PropTypes.object,
};

const mapStateToProps = state => ({
  contentTree: state.contentTree,
});

export default withRouter(connect(mapStateToProps)(App));
