import {
  ACCOUNT_LOGIN_REQUEST,
  ACCOUNT_LOGIN_SUCCESS,
  ACCOUNT_LOGIN_FAILURE,
  ACCOUNT_LOGOUT_REQUEST,
  ACCOUNT_LOGOUT_SUCCESS,
  ACCOUNT_LOGOUT_FAILURE,
  ACCOUNT_SET_TOKEN,
} from 'actions';

const initialAuth = JSON.parse(localStorage.getItem('auth')) || {
  authToken: null,
  identity: null,
  expires: null,
};

const initialState = {
  ...initialAuth,
  error: null,
  isFetching: false,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case ACCOUNT_LOGIN_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };

    case ACCOUNT_LOGIN_SUCCESS:
      return {
        ...state,
        authToken: action.payload.auth,
        expires: action.payload.expires,
        error: null,
        identity: action.payload.identity.name,
        isFetching: false,
      };

    case ACCOUNT_LOGIN_FAILURE:
      return {
        ...state,
        authToken: null,
        error: action.error,
        expires: null,
        identity: null,
        isFetching: false,
      };

    case ACCOUNT_LOGOUT_REQUEST:
      return {
        ...state,
        authToken: null,
        identity: null,
        isFetching: true,
      };

    case ACCOUNT_LOGOUT_SUCCESS:
      return {
        ...state,
        authToken: null,
        error: null,
        expires: null,
        identity: null,
        isFetching: false,
      };

    case ACCOUNT_LOGOUT_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };

    case ACCOUNT_SET_TOKEN:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
