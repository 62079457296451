import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';
import Bowser from 'bowser';

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

const Notification = () => (
  <Fragment>
    <StyledHeader>Advarsel!</StyledHeader>
    Systemet støtter ikke nettleseren du benytter. 
    Vennligst kontakt administrator for hjelp.
  </Fragment>
);

class BrowserRestriction extends Component {
  componentDidMount() {
    const bowser = Bowser.getParser(window.navigator.userAgent);
    const discardVersion = '>0';

    const allowOnlySafari = {
      safari: discardVersion
    };

    const allowOnlyChrome = {
      chrome: discardVersion
    };

    const isBrowserSupported = !!bowser.satisfies({
      windows: allowOnlyChrome,
      android: allowOnlyChrome,
      macos: allowOnlySafari,
      ios: allowOnlySafari
    });

    if (!isBrowserSupported) {
      toast.warn(() => <Notification />, {
        position: 'bottom-right',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false
      });
    }
  }

  render() {
    return this.props.children;
  }
}

BrowserRestriction.propTypes = {
  children: PropTypes.element.isRequired
};

export default BrowserRestriction;
