import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Clear from '@material-ui/icons/Clear';

import Button from 'components/Button';
import Card from 'components/Card';
import ConfirmIcon from './ConfirmIcon';
import Heading from 'components/Heading';
import QuestionIcon from './QuestionIcon';

const icons = {
  confirmation: <QuestionIcon />,
  success: <ConfirmIcon />,
};

const StyledCard = styled(Card)`
  position: relative;
  padding: 64px 24px 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 480px;
`;

const StyledClearIcon = styled(Clear)`
  && {
    cursor: pointer;
    font-size: 35px;
    fill: ${p => p.theme.primary};
    position: absolute;
    top: 15px;
    right: 15px;

    &:hover {
      fill: ${p => p.theme.primaryActive};
    }
  }
`;

const StyledHeading = styled(Heading)`
  && {
    text-align: center;
    margin-bottom: 32px;
  }
`;

const StyledCircle = styled.div`
  color: ${p => p.natures[p.nature].color(p)};
  font-size: 152px;
  height: 152px;
  margin-bottom: 32px;
`;

const StyledParagraph = styled.p`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 32px;
  }
`;

const StyledBtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  button ~ button {
    margin-left: 12px;
  }
`;

const ConfirmationCard = ({
  message,
  nature,
  natures,
  onCloseClick,
  primaryBtnAction,
  primaryBtnLabel,
  secondaryBtnAction,
  secondaryBtnLabel,
  title,
}) => (
  <StyledCard>
    <StyledClearIcon onClick={onCloseClick} />
    <StyledHeading as="h2">{title}</StyledHeading>
    <StyledCircle nature={nature} natures={natures}>
      {icons[nature] && icons[nature]}
    </StyledCircle>
    {message && <StyledParagraph>{message}</StyledParagraph>}
    <StyledBtnContainer>
      {primaryBtnLabel &&
        primaryBtnAction && (
          <Button onClick={primaryBtnAction} size="half">
            {primaryBtnLabel}
          </Button>
        )}
      {secondaryBtnLabel &&
        secondaryBtnAction && (
          <Button nature="secondary" onClick={secondaryBtnAction} size="half">
            {secondaryBtnLabel}
          </Button>
        )}
    </StyledBtnContainer>
  </StyledCard>
);

ConfirmationCard.defaultProps = {
  nature: 'success',
  natures: {
    success: {
      color: p => p.theme.success,
    },
    confirmation: {
      color: p => p.theme.secondary,
    },
  },
};

ConfirmationCard.propTypes = {
  message: PropTypes.string,
  nature: PropTypes.oneOf(['confirmation', 'success']),
  primaryBtnAction: PropTypes.func,
  primaryBtnLabel: PropTypes.string,
  secondaryBtnAction: PropTypes.func,
  secondaryBtnLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default ConfirmationCard;
