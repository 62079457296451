import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { media } from 'common/theme';

const SIZES = {
  h1: 38,
  h2: 30,
  h3: 24,
  h4: 20,
  h5: 18,
  h6: 16,
};

const MOBILE_SIZES = {
  h1: 26,
  h2: 22,
  h3: 20,
  h4: 18,
  h5: 16,
  h6: 14,
};

const MARGINS = {
  h1: 24,
  h2: 24,
  h3: 24,
  h4: 12,
  h5: 12,
  h6: 12,
};

const Heading = styled.span`
  font-size: ${p => SIZES[p.as]}px;
  ${media.mobile`
    font-size: ${p => MOBILE_SIZES[p.as]}px;
  `};
  line-height: 1;
  font-weight: 700;
  color: ${p => p.natures[p.nature]};
  text-transform: ${p => (/h1|h2|h3/.test(p.as) ? 'uppercase' : 'inherit')};
  margin-bottom: ${p => MARGINS[p.as]}px;
  ${p => (p.onClick ? 'cursor: pointer;' : null)};
  word-wrap: break-word;
`;

Heading.defaultProps = {
  as: 'h1',
  nature: 'primary',
  natures: {
    primary: {
      color: p => p.theme.dark,
    },
    error: {
      color: p => p.theme.error,
    },
  },
};

Heading.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  nature: PropTypes.oneOf(['primary', 'error']),
};

export default Heading;
