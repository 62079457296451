import { CALL_API } from 'common/Api';

const getTypes = prefix =>
  ['REQUEST', 'SUCCESS', 'FAILURE'].map(suffix => [prefix, suffix].join('_'));

const getTypesMore = prefix =>
  ['MORE_REQUEST', 'MORE_SUCCESS', 'MORE_FAILURE'].map(suffix =>
    [prefix, suffix].join('_')
  );

export const LIST_CUSTOMERS = 'LIST_CUSTOMERS';
export const LIST_CUSTOMERS_DASHBOARD = 'LIST_CUSTOMERS_DASHBOARD';
export const LIST_OFFERS = 'LIST_OFFERS';
export const LIST_OFFERS_DASHBOARD = 'LIST_OFFERS_DASHBOARD';
export const LIST_ORDERS = 'LIST_ORDERS';
export const LIST_ORDERS_DASHBOARD = 'LIST_ORDERS_DASHBOARD';
export const LIST_PRODUCTS = 'LIST_PRODUCTS';
export const LIST_TODAYS_VISITS = 'LIST_TODAYS_VISITS';
export const LIST_VISITS = 'LIST_VISITS';
export const LIST_PRODUCTS_STOCK_REQUEST = 'LIST_PRODUCTS_STOCK_REQUEST';
export const LIST_PRODUCTS_STOCK_SUCCESS = 'LIST_PRODUCTS_STOCK_SUCCESS';
export const LIST_PRODUCTS_STOCK_FAILURE = 'LIST_PRODUCTS_STOCK_FAILURE';

const pathMap = {
  [LIST_CUSTOMERS]: 'customers-api/Customers/List',
  [LIST_CUSTOMERS_DASHBOARD]: 'customers-api/Customers/List',
  [LIST_OFFERS]: 'offers-api/Offers/List',
  [LIST_OFFERS_DASHBOARD]: 'offers-api/Offers/List',
  [LIST_ORDERS]: 'order-api/Orders/List',
  [LIST_ORDERS_DASHBOARD]: 'order-api/Orders/List',
  [LIST_PRODUCTS]: 'products-api/Products',
  [LIST_TODAYS_VISITS]: 'visits-api/Visits/Day',
  [LIST_VISITS]: 'visits-api/Visits/List',
};

const storeMap = {
  [LIST_CUSTOMERS]: 'customers',
  [LIST_CUSTOMERS_DASHBOARD]: 'customersDashboard',
  [LIST_OFFERS]: 'offers',
  [LIST_OFFERS_DASHBOARD]: 'offersDashboard',
  [LIST_ORDERS]: 'orders',
  [LIST_ORDERS_DASHBOARD]: 'ordersDashboard',
  [LIST_PRODUCTS]: 'products',
  [LIST_TODAYS_VISITS]: 'todaysVisits',
  [LIST_VISITS]: 'visits',
};

const fetchProductListStock = (variantCodes) => ({
  [CALL_API]: {
    types: [
      LIST_PRODUCTS_STOCK_REQUEST,
      LIST_PRODUCTS_STOCK_SUCCESS,
      LIST_PRODUCTS_STOCK_FAILURE,
    ],
    path: `stock-api/Stock/GetByCodes`,
    method: 'post',
    data: variantCodes,
  },
});

const fetchItems = ({ path, prefix, params }) => ({
  [CALL_API]: {
    types: params.from === 0 ? getTypes(prefix) : getTypesMore(prefix),
    path: `${pathMap[prefix]}/${path ? path : ''}`,
    options: { params },
  },
});

const getItems = ({ path, prefix, more = false, params }) => (
  dispatch,
  getStore
) => {
  const { from, size, total, sortBy, sortDir } = getStore().list[
    storeMap[prefix]
  ];

  if (more && total !== null && from + size >= total) {
    return;
  }

  return dispatch(
    fetchItems({
      path,
      prefix,
      params: {
        ...params,
        from: more ? from + size : 0,
        size,
        sortBy,
        sortDir,
      },
    })
  );
};

const sortItems = ({ path, prefix, params }) => (dispatch, getStore) => {
  const { from, size } = getStore().orders;
  dispatch(
    fetchItems({
      path,
      prefix,
      params: {
        ...params,
        from: 0,
        size: from + size,
      },
    })
  );
};

const getAllItems = ({ path, prefix, params }) => (dispatch, getStore) => {
  const { from, size, total, sortBy, sortDir } = getStore().list[
    storeMap[prefix]
  ];
  const totalSize = total - (from + size);

  return dispatch(
    fetchItems({
      path,
      prefix,
      params: {
        ...params,
        from: from + size,
        size: totalSize,
        sortBy,
        sortDir,
      },
    })
  );
};

export const getCustomersDashboard = () =>
  getItems({ prefix: LIST_CUSTOMERS_DASHBOARD });
export const getOffersDashboard = () =>
  getItems({ prefix: LIST_OFFERS_DASHBOARD });
export const getOrdersDashboard = () =>
  getItems({ prefix: LIST_ORDERS_DASHBOARD });

export const getCustomers = more => getItems({ prefix: LIST_CUSTOMERS, more });
export const sortCustomers = (sortBy, sortDir) =>
  sortItems({ prefix: LIST_CUSTOMERS, params: { sortBy, sortDir } });
export const getAllCustomers = () => getAllItems({ prefix: LIST_CUSTOMERS });

export const getOffers = more => getItems({ prefix: LIST_OFFERS, more });
export const sortOffers = (sortBy, sortDir) =>
  sortItems({ prefix: LIST_OFFERS, params: { sortBy, sortDir } });
export const getAllOffers = () => getAllItems({ prefix: LIST_OFFERS });

export const getOrders = more => getItems({ prefix: LIST_ORDERS, more });
export const sortOrders = (sortBy, sortDir) =>
  sortItems({ prefix: LIST_ORDERS, params: { sortBy, sortDir } });
export const getAllOrders = () => getAllItems({ prefix: LIST_ORDERS });

export const getProducts = (path, more) => dispatch => 
  dispatch(getItems({ path, prefix: LIST_PRODUCTS, more }))
  .then(({payload}) => dispatch(fetchProductListStock(payload.entries.map(({code}) => code))));
export const getAllProducts = path => dispatch => 
  dispatch(getAllItems({ path, prefix: LIST_PRODUCTS }))
  .then(({payload}) => dispatch(fetchProductListStock(payload.entries.map(({code}) => code))));

export const getVisits = more => getItems({ prefix: LIST_VISITS, more });
export const sortVisits = (sortBy, sortDir) =>
  sortItems({ prefix: LIST_VISITS, params: { sortBy, sortDir } });
export const getAllVisits = () => getAllItems({ prefix: LIST_VISITS });

export const getTodaysVisits = targetDate =>
  getItems({ prefix: LIST_TODAYS_VISITS, params: { targetDate } });
