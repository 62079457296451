import React from 'react';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import styled from 'styled-components/macro';
import Clear from '@material-ui/icons/Clear';
import 'react-toastify/dist/ReactToastify.min.css';

import { media } from 'common/theme';

const FadeInOut = cssTransition({
  enter: 'fadeIn',
  exit: 'fadeOut',
  duration: [400, 800],
});

const ActionContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 30px;
    cursor: 'pointer';
    fill: ${p => p.theme.light};
    opacity: 0.7;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }
`;

const Notification = styled(ToastContainer)`
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }

  @-webkit-keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
  }

  .Toastify__toast {
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    padding: 12px;
    width: inherit !important;
    box-shadow: 3px 3px 20px 0px rgba(0, 0, 0, 0.3);
    border-radius: 4px;

    ${media.mobile`
      border-radius: 0px;
    `};
  }
  .Toastify__toast--info {
    background: ${p => p.theme.primary};
  }
  .Toastify__toast--success {
    background: ${p => p.theme.primary};
  }
  .Toastify__toast--warning {
    background: ${p => p.theme.secondary};
  }
  .Toastify__toast--error {
    background: ${p => p.theme.error};
  }
`;

const CloseButton = ({ closeToast }) => (
  <ActionContainer>
    <Clear onClick={closeToast} />
  </ActionContainer>
);

const Notifications = () => (
  <Notification
    closeButton={<CloseButton />}
    hideProgressBar
    position={toast.POSITION.TOP_LEFT}
    transition={FadeInOut}
  />
);

export default Notifications;
