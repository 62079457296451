import moment from 'moment';
import { animateScroll } from 'react-scroll';
import { visitStatuses } from 'common/constants';

/* eslint no-useless-escape:0 */
let isMobileResult;

export const isMobile = () => {
  if (typeof isMobileResult !== 'undefined') {
    return isMobileResult;
  }
  var check = false;
  (function(a) {
    // eslint-disable-next-line
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const getDateStr = str => str && moment(str).format('DD.MM.YY');

export const getTimeStr = str => str && moment(str).format('HH:mm');

export const getDateTimeStr = str => `${getDateStr(str)} - ${getTimeStr(str)}`;

export const getDateRangeStr = (startDate, endDate) =>
  `${getDateStr(startDate)} - ${getDateStr(endDate)}`;

export const mergeDateTimeToISO = (date, time) => {
  const momentTime = moment(time, 'HH:mm');
  const momentDate = moment(date);
  momentDate.hour(momentTime.hour());
  momentDate.minute(momentTime.minute());
  return momentDate.toISOString();
};

export const getShippingAddressId = ({
  billingAddress,
  shippingAddresses = [],
  visitingAddress
}) => {
  const address = shippingAddresses.length
    ? shippingAddresses.find(a => a.primaryAddress === true)
    : visitingAddress || billingAddress;
  return address && address.primaryKeyId;
};

export const getShippingAddressOptions = ({
  billingAddress,
  shippingAddresses = [{}],
  visitingAddress
}) =>
  shippingAddresses[0] && Object.keys(shippingAddresses[0]).length
    ? shippingAddresses
    : [visitingAddress || billingAddress];

export const getContactPersonId = ({ contacts = [] }) =>
  contacts.length === 1 ? contacts[0].primaryKeyId : null;

export const denormalizeCart = items =>
  Object.keys(items)
    .reduce((arr, key) => [...arr, items[key]], [])
    .sort((a, b) => {
      if (!a.creationDate && !b.creationDate) {
        return 0;
      }
      if (!a.creationDate) {
        return 1;
      }
      if (!b.creationDate) {
        return -1;
      }

      return moment(b.creationDate) - moment(a.creationDate);
    });

export const normalizeCart = items =>
  items.reduce((acc, el) => {
    acc[[el.variantCode]] = el;
    return acc;
  }, {});

export const calcDiscountPrice = (price, quantity, discount = 0) =>
  price * quantity * (100 - discount) * 0.01;

const isObject = error => typeof error === 'object';

const isArray = error => Array.isArray(error);

const getFirstObjectKey = error => Object.keys(error)[0];

const getFieldName = errors => {
  const firstErrorKey = getFirstObjectKey(errors);
  const firstError = errors[firstErrorKey];
  let fieldName;
  if (isArray(firstError)) {
    const nestedFirstErrorKey = getFirstObjectKey(firstError[0]);
    fieldName = `${firstErrorKey}[0].${nestedFirstErrorKey}`;
  } else if (isObject(firstError)) {
    const nestedFirstErrorKey = getFirstObjectKey(firstError);
    fieldName = `${firstErrorKey}.${nestedFirstErrorKey}`;
  } else {
    fieldName = firstErrorKey;
  }
  return fieldName;
};

export const scrollToFirstError = errors => {
  if (errors) {
    const fieldName = getFieldName(errors);
    const el = document.querySelector(`[name="${fieldName}"]`);
    if (el) {
      const elPosition = el.getBoundingClientRect().top;
      const { scrollTop } =
        document.scrollingElement || document.documentElement;

      const position = elPosition + scrollTop;

      const offset = 200;
      animateScroll.scrollTo(position - offset, {
        smooth: true,
        duration: dist => -(dist / 3)
      });
    }
  }
};

export const translateVisitStatus = (visitStatus) => {
  const status = visitStatuses.find(s => s.value === visitStatus);
  return status ? status.name : visitStatus;
}

export const getTotalPrice = (products) => {
  return products.reduce(
    (prevVal, prod) => prevVal + prod.discountedPrice,
    0
  );
}

export const formatPrice = (price) => {
  const fixedPrice = price.toFixed(2);
  const formattedPrice = fixedPrice && fixedPrice.toString().replace('.', ',');

  return formattedPrice;
}