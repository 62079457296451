import React from 'react';
import PropTypes from 'prop-types';
import Responsive from 'react-responsive';
import { breakpoints } from './theme';

const { tablet, desktop } = breakpoints;

const getWidth = (minWidth, maxWidth) => (min, max, only) => ({
  ...(min && { minWidth }),
  ...(max && { maxWidth }),
  ...(only && { minWidth, maxWidth }),
});

const desktopWidth = getWidth(desktop, null);
const tabletWidth = getWidth(tablet, desktop - 1);
const mobileWidth = getWidth(null, tablet - 1);

export const Desktop = ({ min, max, only, children }) => (
  <Responsive {...desktopWidth(min, max, only)}>{children}</Responsive>
);

export const Tablet = ({ min, max, only, children }) => (
  <Responsive {...tabletWidth(min, max, only)}>{children}</Responsive>
);

export const Mobile = ({ min, max, only, children }) => (
  <Responsive {...mobileWidth(min, max, only)}>{children}</Responsive>
);

const propTypes = {
  children: PropTypes.node.isRequired,
  min: PropTypes.bool,
  max: PropTypes.bool,
  only: PropTypes.bool,
  custom: function(props, propName, componentName) {
    const arr = [props.min, props.max, props.only].filter(o => o);
    if (arr.length === 0) {
      return new Error(
        `One one of 'min', 'max' or 'only' props has to be passed to ${componentName} component`
      );
    } else if (arr.length > 1) {
      return new Error(
        `Only one of 'min', 'max' or 'only' props has to be passed to ${componentName} component`
      );
    }
  },
};

Desktop.propTypes = propTypes;
Tablet.propTypes = propTypes;
Mobile.propTypes = propTypes;
