import {
  CATEGORIES_SUCCESS,
  CATEGORIES_REQUEST,
  CATEGORIES_FAILURE,
  CATEGORIES_OPEN_CATEGORY,
  CATEGORIES_CLOSE_ALL,
} from 'actions';

const initialState = {
  ids: [],
  items: {},
  error: null,
  isFetching: false,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case CATEGORIES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case CATEGORIES_SUCCESS: {
      const data = action.payload;
      const ids = data.map(d => d.documentId);
      const items = data.reduce((acc, curr) => {
        curr.isOpen = false;
        acc[curr.documentId] = curr;
        return acc;
      }, {});

      return {
        ...state,
        isFetching: false,
        ids,
        items,
      };
    }

    case CATEGORIES_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }

    case CATEGORIES_CLOSE_ALL: {
      const ids = state.ids;
      const items = state.items;

      ids.forEach(id => {
        items[id] = { ...items[id], isOpen: false };
      });

      return {
        ...state,
        items,
      };
    }

    case CATEGORIES_OPEN_CATEGORY: {
      const ids = state.ids;
      const items = state.items;
      const categoryCode = action.payload;

      const categoryId = {
        ...Object.values(items).find(c => c.code === categoryCode),
      }.documentId;

      if (categoryId) {
        ids.forEach(id => {
          if (items[id].isOpen && id !== categoryId) {
            items[id] = { ...items[id], isOpen: false };
          }
        });

        items[categoryId] = {
          ...items[categoryId],
          isOpen: !items[categoryId].isOpen,
        };
      }

      return {
        ...state,
        items,
      };
    }

    default:
      return state;
  }
}
