import {
  ACTIVITY_NEXT_STEP,
  CANCEL_ACTIVITY,
  RETRIEVE_ACTIVITY_VALUES,
  SET_ACTIVITY_CUSTOMER,
  SET_ACTIVITY_ID,
  SET_ACTIVITY_SOURCE_ID,
  SET_ACTIVITY_STEP,
  SET_ACTIVITY_TYPE,
  SET_ACTIVITY_SUBMITTING_FAILURE_STATUS,
} from 'actions';
import {} from '../actions/activity';

const initialState = {
  step: 1,
  customer: {},
  id: null,
  sourceId: null,
  isActive: false,
  hasSubmittingFailedPreviously: false
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVITY_TYPE:
      return { ...state, type: action.payload };

    case SET_ACTIVITY_CUSTOMER:
      return { ...state, customer: action.payload, isActive: true };

    case SET_ACTIVITY_STEP:
      return { ...state, step: action.payload };

    case SET_ACTIVITY_ID:
      return { ...state, id: action.payload };

    case SET_ACTIVITY_SOURCE_ID:
      return { ...state, ...action.payload };

    case RETRIEVE_ACTIVITY_VALUES:
      return { ...action.payload };

    case ACTIVITY_NEXT_STEP:
      return { ...state, step: state.step + 1 };

    case SET_ACTIVITY_SUBMITTING_FAILURE_STATUS:
      return { ...state, hasSubmittingFailedPreviously: true };

    case CANCEL_ACTIVITY:
      return initialState;

    default:
      return state;
  }
}
