import React from 'react';
import Link from 'components/Link';

import { getRoute } from './routes';
import {
  HOME,
  CATALOG,
  DASHBOARD,
  LOGIN,
  PRODUCTS,
  PRODUCT,
  NEW_CUSTOMER,
  CUSTOMER,
  CUSTOMERS,
  ORDERS_OFFERS,
  NEW_ORDER,
  ORDER,
  OFFER,
  NEW_OFFER,
  VISITS,
  NEW_VISIT,
  VISIT,
} from './componentMap';

const getRenderLink = type => ({ params, ...props }) => {
  const route = getRoute(type, params);

  return props.render ? (
    props.render({ route })
  ) : (
    <Link to={route.path} {...props} />
  );
};

export const HomeLink = getRenderLink(HOME);

export const DashboardLink = getRenderLink(DASHBOARD);

export const LoginLink = getRenderLink(LOGIN);

export const CatalogLink = getRenderLink(CATALOG);

export const SubcategoryLink = getRenderLink(PRODUCTS);

export const ProductLink = getRenderLink(PRODUCT);

export const CustomerLink = getRenderLink(CUSTOMER);

export const NewCustomerLink = getRenderLink(NEW_CUSTOMER);

export const CustomersLink = getRenderLink(CUSTOMERS);

export const OrdersLink = getRenderLink(ORDERS_OFFERS);

export const NewOrderLink = getRenderLink(NEW_ORDER);

export const OrderLink = getRenderLink(ORDER);

export const NewOfferLink = getRenderLink(NEW_OFFER);

export const OfferLink = getRenderLink(OFFER);

export const VisitsLink = getRenderLink(VISITS);

export const NewVisitLink = getRenderLink(NEW_VISIT);

export const VisitLink = getRenderLink(VISIT);
