import { CALL_API } from 'common/Api';
import { persistCartData, removeProductsWhenPricesUnavailable, fetchProductStock as fetchStockForAddedProduct } from './cart';

export const MOST_ORDERED = 'mostOrdered';
export const LAST_ORDERED = 'lastOrdered';

export const PRODUCTS_STOCK_REQUEST = 'PRODUCTS_STOCK_REQUEST';
export const PRODUCTS_STOCK_SUCCESS = 'PRODUCTS_STOCK_SUCCESS';
export const PRODUCTS_STOCK_FAILURE = 'PRODUCTS_STOCK_FAILURE';

const fetchProductStock = namespace => (variantCodes, customerNo) => ({
  [CALL_API]: {
    types: [
      `${namespace}/${PRODUCTS_STOCK_REQUEST}`,
      `${namespace}/${PRODUCTS_STOCK_SUCCESS}`,
      `${namespace}/${PRODUCTS_STOCK_FAILURE}`,
    ],
    path: `stock-api/Stock/GetByCodes${customerNo ? `/${customerNo}` : ''}`,
    method: 'post',
    data: variantCodes,
  },
});

export const PRODUCTS_REQUEST = 'PRODUCTS_REQUEST';
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS';
export const PRODUCTS_FAILURE = 'PRODUCTS_FAILURE';

export const PRODUCTS_MORE_REQUEST = 'PRODUCTS_MORE_REQUEST';
export const PRODUCTS_MORE_SUCCESS = 'PRODUCTS_MORE_SUCCESS';
export const PRODUCTS_MORE_FAILURE = 'PRODUCTS_MORE_FAILURE';

const orderedProductsEndpointsMap = {
  [MOST_ORDERED]: 'order-api/OrderLines/GetMostOrderedProducts/',
  [LAST_ORDERED]: 'order-api/OrderLines/GetFromLastOrder/',
};

const fetchOrderedProducts = namespace => (customerId, from, size) => ({
  [CALL_API]: {
    types:
      from === 0
        ? [
            `${namespace}/${PRODUCTS_REQUEST}`,
            `${namespace}/${PRODUCTS_SUCCESS}`,
            `${namespace}/${PRODUCTS_FAILURE}`,
          ]
        : [
            `${namespace}/${PRODUCTS_MORE_REQUEST}`,
            `${namespace}/${PRODUCTS_MORE_SUCCESS}`,
            `${namespace}/${PRODUCTS_MORE_FAILURE}`,
          ],
    path: `${orderedProductsEndpointsMap[namespace]}${customerId}`,
    options: {
      params: {
        from,
        size,
      },
    },
  },
});

export const getOrderedProducts = namespace => (more = false) => (
  dispatch,
  getStore
) => {
  const { from, size, total } = getStore()[namespace];
  const { customerNo, documentId } = getStore().activity.customer;

  if (more && total !== null && from + size >= total) {
    return;
  }

  const secondArg = more ? from + size : 0;
  dispatch(fetchOrderedProducts(namespace)(documentId, secondArg, size)).then(
    ({ payload }) => {
      const variantCodes = payload.entries.filter(e => e.hasMatchingProduct).map(e => e.variantCode);
      dispatch(fetchProductStock(namespace)(variantCodes, customerNo));
    }
  );
};

export const PRODUCTS_CLEAR_STORAGE = 'PRODUCTS_CLEAR_STORAGE';

export const clearOrderedProducts = namespace => ({
  type: `${namespace}/${PRODUCTS_CLEAR_STORAGE}`,
});

export const CHANGE_ORDERED_PRODUCT_QUANTITY =
  'CHANGE_ORDERED_PRODUCT_QUANTITY';

const changeOrderedProductQuantityAction = namespace => (
  variantCode,
  quantity
) => ({
  type: `${namespace}/${CHANGE_ORDERED_PRODUCT_QUANTITY}`,
  id: variantCode,
  payload: quantity,
});

export const changeOrderedProductQuantity = namespace => (
  variantCode,
  quantity
) => dispatch => {
  dispatch(
    changeOrderedProductQuantityAction(namespace)(variantCode, quantity)
  );
};

export const ADD_ORDERED_PRODUCT_TO_CART = 'ADD_ORDERED_PRODUCT_TO_CART';

const addOrderedProductToCartAction = data => ({
  type: ADD_ORDERED_PRODUCT_TO_CART,
  payload: data,
  id: data.variantCode,
});

export const addOrderedProductToCart = data => (dispatch, getStore) => {
  dispatch(addOrderedProductToCartAction(data));
  const { customerNo } = getStore().activity.customer;
  return dispatch(fetchStockForAddedProduct([data.variantCode], customerNo))
    .then(removeProductsWhenPricesUnavailable(dispatch))
    .then(() => dispatch(persistCartData()));
};
