import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import { MOST_ORDERED, LAST_ORDERED } from 'actions';
import accountReducer from './accountReducer';
import activityReducer from './activityReducer';
import cartReducer from './cartReducer';
import categoriesReducer from './categoriesReducer';
import contentTreeReducer from './contentTreeReducer';
import customerReducer from './customerReducer';
import listReducer from './listReducer';
import productListReducer from './productListReducer';
import orderedProductsReducer from './orderedProductsReducer';
import offerReducer from './offerReducer';
import orderReducer from './orderReducer';
import productReducer from './productReducer';
import searchReducer from './searchReducer';
import visitReducer from './visitReducer';

import { logSlow } from 'enhance';

const mergeReducerFunctions = (...fns) => (state, action) => fns.reduce((state, fn) => fn(state, action), state);

let reducers = {
  account: accountReducer,
  activity: activityReducer,
  cart: cartReducer,
  categories: categoriesReducer,
  contentTree: contentTreeReducer,
  customer: customerReducer,
  form: formReducer,
  list: mergeReducerFunctions(listReducer, productListReducer),
  [MOST_ORDERED]: orderedProductsReducer(MOST_ORDERED),
  [LAST_ORDERED]: orderedProductsReducer(LAST_ORDERED),
  offer: offerReducer,
  order: orderReducer,
  product: productReducer,
  routing: routerReducer,
  search: searchReducer,
  visit: visitReducer,
};

export default (process.env.NODE_ENV === 'development'
  ? combineReducers(logSlow(reducers))
  : combineReducers(reducers));
