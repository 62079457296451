import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const Card = styled.div`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
`;

Card.defaultProps = {
  as: 'div',
};

Card.propTypes = {
  as: PropTypes.oneOf(['article', 'div', 'header', 'section']),
};

export default Card;
