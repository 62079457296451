import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Redirect from 'react-router-dom/Redirect';

import { loginSSO } from 'actions/account';
import NotFound from 'components/NotFound';

export class AuthHOC extends Component {
  state = {
    ssoLoading: false,
  };
  getAuthId() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('authTokenId');
  }

  render() {
    const { authToken, render, loginSSO } = this.props;
    if (authToken) {
      return render();
    } else if (this.getAuthId()) {
      loginSSO(this.getAuthId())
      return <NotFound>Logger inn...</NotFound>;
    } else {
      return <Redirect to="/login" />;
    }
  }
}

AuthHOC.propTypes = {
  authToken: PropTypes.string,
  identity: PropTypes.string,
};

const mapStateToProps = state => ({
  authToken: state.account.authToken,
  identity: state.account.identity,
});

const mapDispatchToProps = dispatch => ({
  loginSSO: authTokenId => dispatch(loginSSO(authTokenId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthHOC);
