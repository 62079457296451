import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
// import Person from '@material-ui/icons/Person';

import Heading from 'components/Heading';
import { ContentWrapper } from 'components/Layout';
import Logo from 'components/Logo';
import Navigation from 'components/Navigation';
import { media } from 'common/theme';
import { ReactComponent as Person } from './User icon.svg';

const StyledHeader = styled.header`
  width: 100%;
  height: 72px;
  background: #fff;
  z-index: 1000;

  ${media.mobile`
    height: 56px
  `};
`;

const WrapperShadow = styled.div`
  background: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  height: 72px;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  display: flex;
  align-items: center;

  ${media.tablet`
    top: 0;
    position: fixed;
    z-index: 1000;
  `};

  ${media.mobile`
    height: 56px
  `};
`;

const StyledHeading = styled(Heading)`
  && {
    margin-left: auto;
    margin-bottom: 0;
  }
  ${media.mobile`
    display: none;
  `};
`;

const StyledPerson = styled(Person)`
  && {
    padding-left: 8px;
    padding-right: 8px;
    fill: ${p => p.theme.primary};
    ${media.mobile`
      margin-left: auto;
    `};
  }
`;

const Wrapper = styled(ContentWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = ({ name }) => (
  <StyledHeader>
    <WrapperShadow>
      <Wrapper>
        <Logo />
        {name && (
          <Fragment>
            <StyledHeading as="h4">{name}</StyledHeading>
            <StyledPerson />
          </Fragment>
        )}
        <Navigation />
      </Wrapper>
    </WrapperShadow>
  </StyledHeader>
);

const mapStateToProps = state => ({
  name: state.account && state.account.identity,
});

export default connect(mapStateToProps)(Header);
