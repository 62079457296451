import { CALL_API } from 'common/Api';
import { setActivityAsNewOrder, initializeNewOrderForm } from './newOrder';
import {
  persistActivityData,
  selectCustomer,
  setActivityStep,
  setActivityId,
  setOfferAsActivitySource,
  cancelActivity,
} from './activity';
import { initializeCartWData, persistCartData, refreshProductStock } from './cart';
import { navigateNewOrder, navigateNewOffer } from './navigate';

import { setActivityAsNewOffer, initializeNewOfferForm } from './newOffer';

export const OFFER_REQUEST = 'OFFER_REQUEST';
export const OFFER_SUCCESS = 'OFFER_SUCCESS';
export const OFFER_FAILURE = 'OFFER_FAILURE';

const fetchOffer = offerId => ({
  [CALL_API]: {
    types: [OFFER_REQUEST, OFFER_SUCCESS, OFFER_FAILURE],
    path: `offers-api/offer/${offerId}`,
  },
});

export const getOffer = offerId => dispatch => {
  return dispatch(fetchOffer(offerId));
};

export const convertOfferToOrder = offerId => (dispatch, getStore) => {
  const { contactId, organizationId, products } = getStore().offer.data;
  Promise.all([
    dispatch(selectCustomer({ documentId: organizationId })),
  ]).then(() => dispatch(initializeCartWData(products)))
    .then(
    () => {
      const { contacts } = getStore().activity.customer;
      const { primaryKeyId } =
        contacts.find(c => c.primaryKeyId === contactId) || {};
      dispatch(setActivityAsNewOrder());
      dispatch(setActivityStep(3));
      dispatch(setOfferAsActivitySource(offerId));
      dispatch(navigateNewOrder());
      dispatch(initializeNewOrderForm(primaryKeyId));
      dispatch(persistActivityData());
      dispatch(persistCartData());
    },
    () => dispatch(cancelActivity({ noRedirect: true }))
  );
};

export const convertOfferToOffer = offerId => (dispatch, getStore) => {
  const { contactId, organizationId, products } = getStore().offer.data;
  Promise.all([
    dispatch(selectCustomer({ documentId: organizationId })),
  ]).then(() => dispatch(initializeCartWData(products)))
    .then(() => {
      dispatch(setActivityAsNewOffer());
      dispatch(setActivityStep(2));
      dispatch(setOfferAsActivitySource(offerId));
      dispatch(setActivityId(null));
      dispatch(navigateNewOffer());
      dispatch(initializeNewOfferForm(contactId));
      dispatch(persistActivityData());
      dispatch(persistCartData());
  });
};