import styled from 'styled-components/macro';
import { media } from 'common/theme';

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  box-sizing: border-box;

  ${media.mobile`
    padding: 0 10px;
  `};
`;

export default ContentWrapper;
