import { Component } from 'react';
import PropTypes from 'prop-types';
import withRouter from 'react-router-dom/withRouter';
import { animateScroll } from 'react-scroll';

export class ScrollTop extends Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname &&
      (prevProps.location.state && !prevProps.location.state.noScroll)
    ) {
      animateScroll.scrollTo(0, { duration: 200 });
    }
  }

  render() {
    return this.props.children;
  }
}

ScrollTop.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
};

export default withRouter(ScrollTop);
