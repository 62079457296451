export const visitStatuses = [
    {
      name: 'Planlagt',
      value: 'Planned',
    },
    {
      name: 'Ikke truffet',
      value: 'NotCompleted',
    },
    {
      name: 'Truffet',
      value: 'Completed',
    },
];
  