import React from 'react';
import styled from 'styled-components/macro';

import { HomeLink } from 'routing';
import LogoImg from './Relekta.png';

const StyledHomeLink = styled(HomeLink)`
  display: flex;
  align-items: center;
`;

const StyledImage = styled.img`
  max-height: 50px;
  cursor: pointer;
`;

const Logo = () => (
  <StyledHomeLink>
    <StyledImage src={LogoImg} />
  </StyledHomeLink>
);

export default Logo;
