import PropTypes from 'prop-types';
import Link from 'react-router-dom/Link';
import styled from 'styled-components/macro';

const StyledLink = styled(Link)`
  color: ${p => p.theme.primary};

  &:hover {
    color: ${p => p.theme.dark};
    text-decoration: none;
  }
`;

StyledLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default StyledLink;
