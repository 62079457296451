import {
  CLEAR_ITEMS,
  SEARCH_EXTERNAL_CUSTOMER,
  SEARCH_CUSTOMER,
  SEARCH_USER_CUSTOMER,
  SEARCH_OFFER,
  SEARCH_ORDER,
  SEARCH_PRODUCT,
  SEARCH_VARIANT,
  SEARCH_VISIT,
} from 'actions';

const searchStores = {
  externalCustomer: SEARCH_EXTERNAL_CUSTOMER,
  customers: SEARCH_CUSTOMER,
  userCustomers: SEARCH_USER_CUSTOMER,
  offers: SEARCH_OFFER,
  orders: SEARCH_ORDER,
  products: SEARCH_PRODUCT,
  variants: SEARCH_VARIANT,
  visits: SEARCH_VISIT,
};

const initialState = Object.keys(searchStores).reduce((state, container) => {
  state[container] = {
    data: [],
    isFetching: false,
    error: null,
  };
  return state;
}, {});

const searchHandlers = Object.keys(searchStores).reduce(
  (handlers, subStore) => {
    handlers[`${searchStores[subStore]}_REQUEST`] = state => ({
      ...state,
      [subStore]: {
        ...state[subStore],
        isFetching: true,
        error: null,
      },
    });

    handlers[`${searchStores[subStore]}_SUCCESS`] = (state, action) => ({
      ...state,
      [subStore]: {
        data: action.payload.entries,
        isFetching: false,
        error: null,
      },
    });

    handlers[`${searchStores[subStore]}_FAILURE`] = (state, action) => ({
      ...state,
      [subStore]: {
        ...state[subStore],
        isFetching: false,
        error: action.error,
      },
    });

    handlers[`${CLEAR_ITEMS}_${searchStores[subStore]}`] = (state, action) => ({
      ...state,
      [subStore]: {
        ...initialState[subStore],
      },
    });

    return handlers;
  },
  {}
);

export default function app(state = initialState, action) {
  return searchHandlers[action.type]
    ? searchHandlers[action.type](state, action)
    : state;
}
