import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Menu from '@material-ui/icons/Menu';
import { connect } from 'react-redux';
import { transparentize } from 'polished';
import ActionPrompt from 'components/ActionPrompt';

import { CatalogLink, CustomersLink, OrdersLink, VisitsLink } from 'routing';
import { logout } from 'actions';
import { media } from 'common/theme';

const IconWrapper = styled.div`
  cursor: pointer;
`;

const StyledItem = styled.li`
  height: 45px;
  display: block;
  width: 100%;
  text-align: center;
  &:not(:last-child) {
    border-bottom: 1.5px solid ${p => p.theme.dark};
  }

  :hover {
    background-color: ${p => transparentize(0.5, p.theme.light)};
  }

  a,
  p {
    display: block;
    color: ${p => p.theme.primary};
    text-decoration: none;
    font-weight: 700;
    line-height: 45px;
    white-space: nowrap;
    cursor: pointer;
  }

  a:hover,
  p:hover {
    color: ${p => p.theme.primaryActive};
  }
`;

const StyledList = styled.ul`
  transform-origin: top;
  transform: ${p => (p.isOpen ? 'scaleY(1)' : 'scaleY(0)')};
  opacity: ${p => (p.isOpen ? '1' : '0')};
  transition: all 0.2s ease-in-out;
  overflow: hidden;
`;

const StyledNavigation = styled.nav`
  position: relative;
  display: flex;
  align-items: center;

  ${media.tablet`
        position: inherit;
    `} ${IconWrapper} {
    display: none;
  }

  ${IconWrapper} {
    display: flex;
    align-items: center;
    color: ${p => p.theme.primary};
    &:hover {
      color: ${p => p.theme.primaryActive};
    }
  }

  ${StyledList} {
    position: absolute;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    background: #fff;
    padding: 12px 42px;
    top: 60px;
    right: 0;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.5);

    ${media.tablet`
      top: 72px;
    `};

    ${media.mobile`
      top: 56px;
    `};
  }
`;

class Navigation extends Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
    };

    this.menuIcon = React.createRef();

    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.handleMenuToggle = this.handleMenuToggle.bind(this);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleDocumentClick);
  }

  handleDocumentClick(e) {
    if (!this.menuIcon.current || !this.menuIcon.current.contains(e.target)) {
      this.handleMenuClose();
    }
  }

  handleMenuClose() {
    document.body.removeEventListener('click', this.handleDocumentClick);
    this.setState({
      isOpen: false,
    });
  }

  handleMenuToggle() {
    if (!this.state.isOpen) {
      document.body.addEventListener('click', this.handleDocumentClick);
      this.setState({ isOpen: true });
    } else {
      this.handleMenuClose();
    }
  }

  render() {
    return this.props.isAuthenticated ? (
      <StyledNavigation>
        <IconWrapper onClick={this.handleMenuToggle} ref={this.menuIcon}>
          <Menu style={{ fontSize: 48 }} />
        </IconWrapper>
        <StyledList isOpen={this.state.isOpen}>
          <StyledItem no="0">
            <CustomersLink onClick={this.handleMenuClose}>Kunder</CustomersLink>
          </StyledItem>
          <StyledItem no="1">
            <OrdersLink onClick={this.handleMenuClose}>
              Ordre og tilbud
            </OrdersLink>
          </StyledItem>
          <StyledItem no="2">
            <VisitsLink onClick={this.handleMenuClose}>Planlegger</VisitsLink>
          </StyledItem>
          <StyledItem no="3">
            <CatalogLink onClick={this.handleMenuClose}>Produkter</CatalogLink>
          </StyledItem>
          <ActionPrompt
            action={this.props.logout}
            message="Alle aktiviteter vil bli avbrutt!"
          >
            {open => (
              <StyledItem no="4">
                <p onClick={open}>Logg ut</p>
              </StyledItem>
            )}
          </ActionPrompt>
        </StyledList>
      </StyledNavigation>
    ) : null;
  }
}

Navigation.propTypes = {
  logout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: !!state.account.authToken || false,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation);
