import { CALL_API } from 'common/Api';

export const ORDER_REQUEST = 'ORDER_REQUEST';
export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const ORDER_FAILURE = 'ORDER_FAILURE';

const fetchOrder = orderId => ({
  [CALL_API]: {
    types: [ORDER_REQUEST, ORDER_SUCCESS, ORDER_FAILURE],
    path: `order-api/Order/${orderId}`,
  },
});

export const getOrder = orderId => dispatch => {
  return dispatch(fetchOrder(orderId));
};
