import { CALL_API } from 'common/Api';
import { normalizeCart } from 'common/utils';
import { toast } from 'react-toastify';

const TEMP_CART = 'TEMP_CART';

export const persistCartData = () => (dispatch, getStore) => {
  const { cart } = getStore();
  
  localStorage.setItem(TEMP_CART, JSON.stringify(cart));
};

export const RETRIEVE_CART_DATA = 'RETRIEVE_CART_DATA';

export const retrieveCartData = (customer) => dispatch => {
  const cart = JSON.parse(localStorage.getItem(TEMP_CART));

  if (cart) {
    dispatch({
      type: RETRIEVE_CART_DATA,
      payload: cart,
    });
    const variantCodes = Object.keys(cart);
    variantCodes.length && dispatch(refreshProductStock(variantCodes, customer)).then(removeProductsWhenPricesUnavailable(dispatch));
  }
};

export const INITIALIZE_CART_WITH_DATA = 'INITIALIZE_CART_WITH_DATA';

export const initializeCartWData = cart => dispatch => {
  const normalizedCart = normalizeCart(cart);

  dispatch({
    type: INITIALIZE_CART_WITH_DATA,
    payload: normalizedCart,
  });
  const variantCodes = Object.keys(normalizedCart);
  return variantCodes.length && dispatch(refreshProductStock(variantCodes)).then(removeProductsWhenPricesUnavailable(dispatch));
};

export const PRODUCT_STOCK_REQUEST = 'PRODUCT_STOCK_REQUEST';
export const PRODUCT_STOCK_SUCCESS = 'PRODUCT_STOCK_SUCCESS';
export const PRODUCT_STOCK_FAILURE = 'PRODUCT_STOCK_FAILURE';

export const fetchProductStock = (variantCodes, customerNo) => ({
  [CALL_API]: {
    types: [
      PRODUCT_STOCK_REQUEST,
      PRODUCT_STOCK_SUCCESS,
      PRODUCT_STOCK_FAILURE,
    ],
    path: `stock-api/Stock/GetByCodes${customerNo ? `/${customerNo}` : ''}`,
    method: 'post',
    data: variantCodes,
  },
});

export const REFRESH_PRODUCT_STOCK_REQUEST = 'REFRESH_PRODUCT_STOCK_REQUEST';
export const REFRESH_PRODUCT_STOCK_SUCCESS = 'REFRESH_PRODUCT_STOCK_SUCCESS';
export const REFRESH_PRODUCT_STOCK_FAILURE = 'REFRESH_PRODUCT_STOCK_FAILURE';

export const refreshProductStock = (variantCodes, customer) => (dispatch, getStore) => {
  let { customerNo } = getStore().activity.customer;
  if (!customerNo) {
    customerNo = customer;
  }

  return dispatch({
    [CALL_API]: {
      types: [
        REFRESH_PRODUCT_STOCK_REQUEST,
        REFRESH_PRODUCT_STOCK_SUCCESS,
        REFRESH_PRODUCT_STOCK_FAILURE,
      ],
      path: `stock-api/Stock/GetByCodes${customerNo ? `/${customerNo}` : ''}`,
      method: 'post',
      data: variantCodes,
    }
  });
};

export const ADD_CART_PRODUCT = 'ADD_CART_PRODUCT';

const addProductAction = data => ({
  type: ADD_CART_PRODUCT,
  payload: data,
  id: data.variantCode,
});

export const addProduct = data => (dispatch, getStore) => {
  const { [data.variantCode]: prod } = getStore().cart;
  if (prod) {
    const totalQuantity = prod.quantity + (data.quantity || prod.minQuantity);
    const quantity = Math.min(totalQuantity, prod.maxQuantity);
    dispatch(changeProductQuantityAction(data.variantCode, quantity));
  } else {
    dispatch(addProductAction(data));
  }
  const { customerNo } = getStore().activity.customer;
  const stockFunc = prod ? refreshProductStock : fetchProductStock;
  return dispatch(stockFunc([data.variantCode], customerNo))
    .then(removeProductsWhenPricesUnavailable(dispatch))
    .then(() =>
      dispatch(persistCartData())
    );
};

export const removeProductsWhenPricesUnavailable = (dispatch) => ({invokedWith, payload}) => {
  return Promise.all(invokedWith.map(variantCode => {
    const variantData = payload.find(x => x.variantCode == variantCode);
    if (variantData && variantData.pricesUnavailable) {      
      toast.warn(`Produkt ${variantData.productNumber} er fjernet fordi pris var utilgjengelig`);
      return dispatch(removeProduct(variantCode));
    }
  }));
};

export const REMOVE_CART_PRODUCT = 'REMOVE_CART_PRODUCT';

const removeProductAction = variantCode => ({
  type: REMOVE_CART_PRODUCT,
  id: variantCode,
});

export const removeProduct = variantCode => dispatch => {
  dispatch(removeProductAction(variantCode));
  dispatch(persistCartData());
};

export const CHANGE_PRODUCT_QUANTITY = 'CHANGE_PRODUCT_QUANTITY';

const changeProductQuantityAction = (variantCode, val) => ({
  type: CHANGE_PRODUCT_QUANTITY,
  id: variantCode,
  payload: val,
});

export const changeProductQuantity = (variantCode, val) => dispatch => {
  dispatch(changeProductQuantityAction(variantCode, val));
  dispatch(persistCartData());
};

export const CHANGE_PRODUCT_DISCOUNT = 'CHANGE_PRODUCT_DISCOUNT';

const changeProductDiscountAction = (variantCode, payload) => ({
  type: CHANGE_PRODUCT_DISCOUNT,
  id: variantCode,
  payload,
});

export const changeProductDiscount = (variantCode, payload) => dispatch => {
  dispatch(changeProductDiscountAction(variantCode, payload));
  dispatch(persistCartData());
};

export const TOGGLE_PRODUCT_PRESENTATION = 'TOGGLE_PRODUCT_PRESENTATION';

const toggleProductPresentationAction = variantCode => ({
  type: TOGGLE_PRODUCT_PRESENTATION,
  id: variantCode,
});

export const toggleProductPresentation = variantCode => dispatch => {
  dispatch(toggleProductPresentationAction(variantCode));
  dispatch(persistCartData());
};

export const CLEAR_CART = 'CLEAR_CART';

const clearCartAction = () => ({
  type: CLEAR_CART,
});

export const clearCart = () => dispatch => {
  localStorage.removeItem(TEMP_CART);
  dispatch(clearCartAction());
};
