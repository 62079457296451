import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withRouter from 'react-router-dom/withRouter';
import { connect } from 'react-redux';

import ActivityNotification from 'components/ActivityNotification';
import { ContentWrapper } from 'components/Layout';
import { getRoute, VISIT, NEW_VISIT, NEW_ORDER, NEW_OFFER } from 'routing';
import { cancelActivity, navigateToActivity, retrieveActivity } from 'actions';
import {
  NEW_OFFER_ACTIVITY,
  NEW_ORDER_ACTIVITY,
  NEW_VISIT_ACTIVITY,
  VISIT_ACTIVITY,
} from 'actions';

export class Activity extends Component {
  componentDidMount() {
    this.props.retrieveActivity();
  }

  isVisible() {
    const { activity, isLoggedIn, location } = this.props;
    const routesMap = {
      [NEW_OFFER_ACTIVITY]: getRoute(NEW_OFFER).path,
      [NEW_ORDER_ACTIVITY]: getRoute(NEW_ORDER).path,
      [NEW_VISIT_ACTIVITY]: getRoute(NEW_VISIT).path,
      [VISIT_ACTIVITY]:
        getRoute(VISIT, [activity.id]) && getRoute(VISIT, [activity.id]).path,
    };
    return (
      isLoggedIn &&
      activity.isActive &&
      activity.type &&
      routesMap[activity.type] !== location.pathname
    );
  }

  render() {
    const { activity, cancelActivity, navigateToActivity } = this.props;
    const { customer, type } = activity;
    return (
      <ContentWrapper>
        {this.isVisible() && (
          <ActivityNotification
            customerName={customer.name}
            nature={type}
            primaryBtnAction={navigateToActivity}
            secondaryBtnAction={cancelActivity}
          />
        )}
      </ContentWrapper>
    );
  }
}

Activity.propTypes = {
  activity: PropTypes.shape({
    customer: PropTypes.object,
    id: PropTypes.string,
    isActive: PropTypes.bool,
    type: PropTypes.oneOf([
      NEW_OFFER_ACTIVITY,
      NEW_ORDER_ACTIVITY,
      NEW_VISIT_ACTIVITY,
      VISIT_ACTIVITY,
    ]),
  }),
  cancelActivity: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  navigateToActivity: PropTypes.func.isRequired,
  retrieveActivity: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  activity: state.activity,
  isLoggedIn: !!state.account.authToken,
});

const mapDispatchToProps = dispatch => ({
  cancelActivity: () => dispatch(cancelActivity({ noRedirect: true })),
  navigateToActivity: () => dispatch(navigateToActivity()),
  retrieveActivity: () => dispatch(retrieveActivity()),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Activity)
);
