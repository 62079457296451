import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import ActionPrompt from 'components/ActionPrompt';
import Button from 'components/Button';
import Card from 'components/Card';
import { media } from 'common/theme';
import {
  NEW_OFFER_ACTIVITY,
  NEW_ORDER_ACTIVITY,
  NEW_VISIT_ACTIVITY,
  VISIT_ACTIVITY,
} from 'actions';

const StyledCard = styled(Card)`
  display: flex;
  margin-bottom: 24px;
  padding-right: 12px;
`;

const StyledBelt = styled.div`
  background-color: ${p => p.natures[p.nature].color(p)};
  min-height: 100%;
  width: 5px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-right: 12px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledText = styled.p`
  margin-top: 24px;
  margin-bottom: 12px;
  text-align: center;
`;

const StyledBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.mobile`
    flex-direction: column;
  `};

  ${Button}:first-of-type {
    margin-right: 12px;

    ${media.mobile`
      margin-right: 0;
      margin-bottom: 12px;
    `};
  }
`;

const ActivityNotification = ({
  customerName,
  nature,
  natures,
  primaryBtnAction,
  secondaryBtnAction,
}) => (
  <StyledCard>
    <StyledBelt natures={natures} nature={nature} />
    <StyledContent>
      <StyledText>{natures[nature].text({ customerName })}</StyledText>
      <StyledBtnContainer>
        <ActionPrompt
          action={secondaryBtnAction}
          message="Vil du avslutte aktiviteten?"
        >
          {open => (
            <Button nature="secondary" onClick={open}>
              {natures[nature].cancelBtn}
            </Button>
          )}
        </ActionPrompt>
        <Button onClick={primaryBtnAction}>{natures[nature].goBtn}</Button>
      </StyledBtnContainer>
    </StyledContent>
  </StyledCard>
);

ActivityNotification.propTypes = {
  customerName: PropTypes.string.isRequired,
  nature: PropTypes.oneOf([
    NEW_OFFER_ACTIVITY,
    NEW_ORDER_ACTIVITY,
    NEW_VISIT_ACTIVITY,
    VISIT_ACTIVITY,
  ]).isRequired,
  primaryBtnAction: PropTypes.func.isRequired,
  secondaryBtnAction: PropTypes.func.isRequired,
};

ActivityNotification.defaultProps = {
  natures: {
    [NEW_OFFER_ACTIVITY]: {
      color: p => p.theme.secondary,
      text: p => `Du jobber nå med ${p.customerName}, tilbud`,
      cancelBtn: 'gå ut av tilbud',
      goBtn: 'tilbake til tilbud',
    },
    [NEW_ORDER_ACTIVITY]: {
      color: p => p.theme.primary,
      text: p => `Du jobber nå med ordre for ${p.customerName}`,
      cancelBtn: 'gå ut av ordre',
      goBtn: 'tilbake til ordre',
    },
    [NEW_VISIT_ACTIVITY]: {
      color: p => p.theme.success,
      text: p => `Du jobber nå med ${p.customerName}, nytt besok`,
      cancelBtn: 'gå ut av besøk',
      goBtn: 'tilbake til besøk',
    },
    [VISIT_ACTIVITY]: {
      color: p => p.theme.success,
      text: p => `Du jobber nå med ${p.customerName}, besok`,
      cancelBtn: 'gå ut av besøk',
      goBtn: 'tilbake til besøk',
    },
  },
};

export default ActivityNotification;
