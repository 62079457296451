import React from 'react';
import styled from 'styled-components/macro';

import Heading from 'components/Heading';
import { ContentWrapper } from 'components/Layout';

const StyledContentWrapper = styled(ContentWrapper)`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledHeading = styled(Heading)`
  color: ${p => p.theme.grey};
`;

const NotFound = ({ children }) => (
  <StyledContentWrapper>
    <StyledHeading as="h3">{children}</StyledHeading>
  </StyledContentWrapper>
);

export default NotFound;
