import { destroy } from 'redux-form';

import { CALL_API } from 'common/Api';
import {
  navigateNewOffer,
  navigateNewOrder,
  navigateNewVisit,
  navigateOrdersOffers,
  navigateVisitDetails,
  navigateVisits,
} from './navigate';

import { clearCart, retrieveCartData } from './cart';
import { NEW_OFFER_ACTIVITY, NEW_OFFER_FORM } from './newOffer';
import { NEW_ORDER_ACTIVITY, NEW_ORDER_FORM } from './newOrder';
import { NEW_VISIT_ACTIVITY, NEW_VISIT_FORM } from './newVisit';
import { VISIT_ACTIVITY, VISIT_FORM } from './visit';

export const SET_ACTIVITY_TYPE = 'SET_ACTIVITY_TYPE';

export const setActivityType = type => ({
  type: SET_ACTIVITY_TYPE,
  payload: type,
});

export const SET_ACTIVITY_SUBMITTING_FAILURE_STATUS = 'SET_ACTIVITY_SUBMITTING_FAILURE_STATUS';

export const setActivitySubmittingFailureStatusAction = () => ({
  type: SET_ACTIVITY_SUBMITTING_FAILURE_STATUS
});

export const setActivitySubmittingFailureStatus = () => dispatch => {
  dispatch(setActivitySubmittingFailureStatusAction());
  dispatch(persistActivityData());
};

export const SET_ACTIVITY_STEP = 'SET_ACTIVITY_STEP';

export const setActivityStep = step => ({
  type: SET_ACTIVITY_STEP,
  payload: step,
});

export const SET_ACTIVITY_ID = 'SET_ACTIVITY_ID';

export const setActivityId = id => ({
  type: SET_ACTIVITY_ID,
  payload: id,
});

export const SET_ACTIVITY_SOURCE_ID = 'SET_ACTIVITY_SOURCE_ID';

const OFFER_NAME = 'Offer';
export const setOfferAsActivitySource = sourceId => ({
  type: SET_ACTIVITY_SOURCE_ID,
  payload: { sourceId, sourceType: OFFER_NAME },
});

const VISIT_NAME = 'Visit';
export const setVisitAsActivitySource = sourceId => ({
  type: SET_ACTIVITY_SOURCE_ID,
  payload: { sourceId, sourceType: VISIT_NAME },
});

export const ACTIVITY_NEXT_STEP = 'ACTIVITY_NEXT_STEP';

const nextStepAction = () => ({
  type: ACTIVITY_NEXT_STEP,
});

export const nextStep = () => dispatch => {
  dispatch(nextStepAction());
  dispatch(persistActivityData());
};

export const RETRIEVE_ACTIVITY_VALUES = 'RETRIEVE_ACTIVITY_VALUES';

const TEMP_ACTIVITY = 'TEMP_ACTIVITY';

const retrieveActivityValues = values => ({
  type: RETRIEVE_ACTIVITY_VALUES,
  payload: values,
});

export const retrieveActivity = () => (dispatch) => {
  const activity = JSON.parse(localStorage.getItem(TEMP_ACTIVITY));

  if (activity) {
    dispatch(retrieveCartData(activity.customer.customerNo));
    dispatch(retrieveActivityValues(activity));
  }
};

const formMap = {
  [NEW_OFFER_ACTIVITY]: NEW_OFFER_FORM,
  [NEW_ORDER_ACTIVITY]: NEW_ORDER_FORM,
  [NEW_VISIT_ACTIVITY]: NEW_VISIT_FORM,
  [VISIT_ACTIVITY]: VISIT_FORM,
};

export const persistActivityData = () => (dispatch, getStore) => {
  const { activity, form } = getStore();
  const { id, type } = activity;
  const formName = `${[formMap[type]]}${id ? `_${id}` : ''}`;
  const formValues = form[formName];

  localStorage.setItem(
    TEMP_ACTIVITY,
    JSON.stringify({ ...activity, formValues: formValues && formValues.values })
  );
};

const navigateMap = {
  [NEW_OFFER_ACTIVITY]: navigateNewOffer,
  [NEW_ORDER_ACTIVITY]: navigateNewOrder,
  [NEW_VISIT_ACTIVITY]: navigateNewVisit,
  [VISIT_ACTIVITY]: navigateVisitDetails,
};

export const navigateToActivity = () => (dispatch, getStore) => {
  const { type, id } = getStore().activity;
  dispatch(navigateMap[type]([id]));
};

export const CANCEL_ACTIVITY = 'CANCEL_ACTIVITY';

export const cancelActivityAction = () => ({
  type: CANCEL_ACTIVITY,
});

const redirectMap = {
  [NEW_OFFER_ACTIVITY]: navigateOrdersOffers,
  [NEW_ORDER_ACTIVITY]: navigateOrdersOffers,
  [NEW_VISIT_ACTIVITY]: navigateVisits,
  [VISIT_ACTIVITY]: navigateVisits,
};

export const cancelActivity = ({ noRedirect, leaveData } = {}) => (
  dispatch,
  getStore
) => {
  localStorage.removeItem(TEMP_ACTIVITY);
  const { type, id } = getStore().activity;
  !noRedirect && dispatch(redirectMap[type]());
  dispatch(cancelActivityAction());
  !leaveData && dispatch(destroy(`${[formMap[type]]}${id ? `_${id}` : ''}`));
  !leaveData && dispatch(clearCart());
};

export const ACTIVITY_CUSTOMER_REQUEST = 'ACTIVITY_CUSTOMER_REQUEST';
export const ACTIVITY_CUSTOMER_SUCCESS = 'ACTIVITY_CUSTOMER_SUCCESS';
export const ACTIVITY_CUSTOMER_FAILURE = 'ACTIVITY_CUSTOMER_FAILURE';

const fetchCustomer = customerId => ({
  [CALL_API]: {
    types: [
      ACTIVITY_CUSTOMER_REQUEST,
      ACTIVITY_CUSTOMER_SUCCESS,
      ACTIVITY_CUSTOMER_FAILURE,
    ],
    path: `customers-api/Customer/${customerId}`,
  },
});

export const SET_ACTIVITY_CUSTOMER = 'SET_ACTIVITY_CUSTOMER';

const setActivityCustomer = customer => ({
  type: SET_ACTIVITY_CUSTOMER,
  payload: customer,
});

export const selectCustomer = ({ documentId }) => dispatch => {
  return dispatch(fetchCustomer(documentId)).then(({ payload }) =>
    dispatch(setActivityCustomer(payload))
  );
};
