import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Modal from '@material-ui/core/Modal';

const StyledModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 480px;
  padding: 10px;
  box-sizing: border-box;
`;

const SimpleModal = ({ children, isOpen, onCloseClick }) => (
  <Modal disableAutoFocus onClose={onCloseClick} open={isOpen}>
    <StyledModalContainer>{children}</StyledModalContainer>
  </Modal>
);

SimpleModal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  onCloseClick: PropTypes.func.isRequired,
};

SimpleModal.defaultProps = {
  isOpen: false,
};

export default SimpleModal;
