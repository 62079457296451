import { ORDER_SUCCESS, ORDER_REQUEST, ORDER_FAILURE } from 'actions';

const initialState = {
  data: {},
  error: null,
  isFetching: false,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case ORDER_REQUEST:
      return {
        ...state,
        data: {},
        isFetching: true,
      };

    case ORDER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };

    case ORDER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
}
