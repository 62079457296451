import axios, { CancelToken } from 'axios';
import { accountSetToken } from 'actions';

const BASE_URL_EPI =
  (window.globalConfiguration && window.globalConfiguration.BASE_URL_EPI) ||
  'http://selgerlosningdev.relekta.no/api/';
export const BASE_URL_APP =
  (window.globalConfiguration && window.globalConfiguration.BASE_URL_APP) ||
  'https://services.selgerlosningdev.relekta.no/';

const AXIOS_BASE_CONFIG = {
  timeout: 100000,
  withCredentials: true,
  getAllResponseHeaders: true,
};

export const CALL_API = 'CALL_API';
export const API_EPI = 'API_EPI';
export const API_APP = 'API_APP';

export default class Api {
  constructor(store) {
    this[API_EPI] = axios.create({
      ...AXIOS_BASE_CONFIG,
      baseURL: BASE_URL_EPI,
    });
    this[API_APP] = axios.create({
      ...AXIOS_BASE_CONFIG,
      baseURL: BASE_URL_APP,
      withCredentials: false,
    });
    this.store = store;

    this.configureInterceptors();
  }

  getCancelTokenSource() {
    return CancelToken.source();
  }

  isCancel(error){
    return axios.isCancel(error);
  }

  configureInterceptors() {
    [API_EPI, API_APP].forEach(apiName => {
      const api = this[apiName];
      api.interceptors.request.use(config => {
        const state = this.store.getState();

        if (state.account && state.account.authToken) {
          config.headers.common['X-Auth'] = state.account.authToken;
        }

        return config;
      });

      api.interceptors.response.use(response => {
        // console.log(response.headers);
        if (response.headers && response.headers['x-auth']) {
          this.updateAuthToken(response.headers['x-auth']);
        }

        return response;
      });
    });
  }

  updateAuthToken(newToken) {
    const state = this.store.getState();
    // console.log(newToken);
    if (state.account.authToken !== newToken) {
      const auth = {
        authToken: newToken,
        identity: state.account.identity,
        expires: state.account.expires,
      };
      // console.log(auth);
      this.store.dispatch(accountSetToken(auth));
    }
  }
}
