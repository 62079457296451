import React from 'react';

const QuestionIcon = props => (
  <svg viewBox="0 0 154 154" width="1em" height="1em" {...props}>
    <path
      d="M77 153c41.974 0 76-34.026 76-76S118.974 1 77 1 1 35.026 1 77s34.026 76 76 76zm-7-50.36c0-2.187.586-3.84 1.758-4.96C72.93 96.56 74.635 96 76.875 96c2.161 0 3.835.573 5.02 1.719 1.184 1.146 1.777 2.786 1.777 4.922 0 2.057-.6 3.678-1.797 4.863-1.198 1.185-2.865 1.777-5 1.777-2.188 0-3.88-.58-5.078-1.738-1.198-1.159-1.797-2.793-1.797-4.902zm-.453-13.656v-2.89c0-2.5.534-4.675 1.601-6.524 1.068-1.849 3.021-3.815 5.86-5.898 2.734-1.953 4.538-3.542 5.41-4.766a6.901 6.901 0 0 0 1.309-4.101c0-1.693-.625-2.982-1.875-3.868-1.25-.885-2.995-1.328-5.235-1.328-3.906 0-8.36 1.276-13.36 3.828L59 54.883C64.807 51.628 70.966 50 77.477 50c5.364 0 9.628 1.29 12.793 3.867 3.164 2.578 4.746 6.016 4.746 10.313 0 2.864-.651 5.338-1.954 7.422-1.302 2.083-3.776 4.427-7.421 7.03-2.5 1.85-4.082 3.256-4.746 4.22-.665.963-.997 2.226-.997 3.789v2.343H69.547z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={2}
      fillRule="evenodd"
    />
  </svg>
);

export default QuestionIcon;
