import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Breadcrumb from 'components/Breadcrumb';

const mapStateToProps = state => ({
  // items: state.breadcrumb || [],
  state,
});

export default withRouter(connect(mapStateToProps)(Breadcrumb));
