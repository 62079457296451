import {
  LIST_PRODUCTS_STOCK_REQUEST,
  LIST_PRODUCTS_STOCK_SUCCESS,
  LIST_PRODUCTS_STOCK_FAILURE,    
} from 'actions';

export default function app(state, action) {
  switch (action.type) {
    case LIST_PRODUCTS_STOCK_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case LIST_PRODUCTS_STOCK_SUCCESS:
      const { payload } = action;
      const { products } = state;

      return {
        ...state,
        isFetching: false,
        products: {
          ...products,
          data: products.data.map(product => {
            const stockProduct = payload.find(({ productCode }) => productCode === product.code);
            
            return {
              ...product,
              priceFrom: stockProduct
                ? stockProduct.price
                : product.priceFrom,
            };
          })
        }
      };

    case LIST_PRODUCTS_STOCK_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
}
