import { CALL_API, API_EPI } from 'common/Api';

export const CONTENT_TREE_REQUEST = 'CONTENT_TREE_REQUEST';
export const CONTENT_TREE_SUCCESS = 'CONTENT_TREE_SUCCESS';
export const CONTENT_TREE_FAILURE = 'CONTENT_TREE_FAILURE';

const fetchContentTree = () => ({
  [CALL_API]: {
    api: API_EPI,
    types: [CONTENT_TREE_REQUEST, CONTENT_TREE_SUCCESS, CONTENT_TREE_FAILURE],
    path: 'content-tree'
  }
});

export const getContentTree = () => (dispatch, getStore) => {
  if (getStore().contentTree.tree) {
    return Promise.resolve();
  }
  return dispatch(fetchContentTree());
};
