import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { ThemeProvider } from 'styled-components/macro';

import { theme } from 'common/theme';
import store, { history } from './store';
import registerServiceWorker from './registerServiceWorker';

import App from './containers/App/';
import { ScrollTop, BrowserRestriction } from 'enhance';

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <ScrollTop>
          <BrowserRestriction>
            <App />
          </BrowserRestriction>
        </ScrollTop>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

registerServiceWorker();
