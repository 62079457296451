import { SubmissionError } from 'redux-form';

import { CALL_API } from 'common/Api';
import {
  persistActivityData,
  selectCustomer,
  setActivityId,
  setVisitAsActivitySource,
  setActivityStep,
  setActivityType,
} from './activity';
import { persistCartData, refreshProductStock } from './cart';
import { navigateNewOrder, navigateNewOffer } from './navigate';
import { setActivityAsNewOrder, initializeNewOrderForm } from './newOrder';
import { setActivityAsNewOffer, initializeNewOfferForm } from './newOffer';

export const VISIT_ACTIVITY = 'VISIT_ACTIVITY';
export const VISIT_FORM = 'VisitForm';

export const VISIT_REQUEST = 'VISIT_REQUEST';
export const VISIT_SUCCESS = 'VISIT_SUCCESS';
export const VISIT_FAILURE = 'VISIT_FAILURE';

const fetchVisit = visitId => ({
  [CALL_API]: {
    types: [VISIT_REQUEST, VISIT_SUCCESS, VISIT_FAILURE],
    path: `visits-api/Visit/${visitId}`,
  },
});

export const getVisit = visitId => dispatch => {
  return dispatch(fetchVisit(visitId));
};

export const SAVE_VISIT_REQUEST = 'SAVE_VISIT_REQUEST';
export const SAVE_VISIT_SUCCESS = 'SAVE_VISIT_SUCCESS';
export const SAVE_VISIT_FAILURE = 'SAVE_VISIT_FAILURE';

const saveVisitAction = (data, visitId) => ({
  [CALL_API]: {
    types: [SAVE_VISIT_REQUEST, SAVE_VISIT_SUCCESS, SAVE_VISIT_FAILURE],
    path: `visits-api/Visit/${visitId}`,
    method: 'put',
    throwable: SubmissionError,
    data,
  },
});

export const saveVisit = (data, visitId) => dispatch => {
  return dispatch(saveVisitAction(data, visitId));
};

export const setActivityAsVisit = id => dispatch => {
  dispatch(setActivityType(VISIT_ACTIVITY));
  dispatch(setActivityId(id));
};

export const convertVisitToOrder = () => (dispatch, getStore) => {
  const { cart } = getStore();
  const { contactId, organizationId } = getStore().visit.data;
  const variantCodes = cart ? Object.keys(cart) : null;
  Promise.all([
    dispatch(selectCustomer({ documentId: organizationId })),
  ]).then(() => variantCodes && dispatch(refreshProductStock(variantCodes)))
    .then(() => {
    const { customer, id } = getStore().activity;
    const { primaryKeyId } =
      customer.contacts.find(c => c.primaryKeyId === contactId) || {};
    dispatch(setActivityAsNewOrder());
    dispatch(setActivityStep(3));
    dispatch(setVisitAsActivitySource(id));
    dispatch(setActivityId(null));
    dispatch(navigateNewOrder());
    dispatch(initializeNewOrderForm(primaryKeyId));
    dispatch(persistActivityData());
    dispatch(persistCartData());
  });
};

export const convertVisitToOffer = () => (dispatch, getStore) => {
  const { cart } = getStore();
  const { contactId, organizationId } = getStore().visit.data;
  const variantCodes = cart ? Object.keys(cart) : null;
  Promise.all([
    dispatch(selectCustomer({ documentId: organizationId })),
    variantCodes && dispatch(refreshProductStock(variantCodes)),
  ]).then(() => {
    const { customer, id } = getStore().activity;
    const { primaryKeyId } =
      customer.contacts.find(c => c.primaryKeyId === contactId) || {};
    dispatch(setActivityAsNewOffer());
    dispatch(setActivityStep(2));
    dispatch(setVisitAsActivitySource(id));
    dispatch(setActivityId(null));
    dispatch(navigateNewOffer());
    dispatch(initializeNewOfferForm(primaryKeyId));
    dispatch(persistActivityData());
    dispatch(persistCartData());
  });
};
