import { VISIT_SUCCESS, VISIT_REQUEST, VISIT_FAILURE, SAVE_VISIT_SUCCESS } from 'actions';

const initialState = {
  data: {},
  error: null,
  isFetching: false,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case VISIT_REQUEST:
      return {
        ...state,
        data: {},
        isFetching: true,
      };

    case VISIT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };

    case VISIT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case SAVE_VISIT_SUCCESS: 
    const newData = {...state.data, ...action.invokedWith};
      return {
        ...state,
        data: {...state.data, ...action.invokedWith},
      };
    default:
      return state;
  }
}