import {
  ADD_CART_PRODUCT,
  // PRODUCT_STOCK_REQUEST,
  PRODUCT_STOCK_SUCCESS,
  // PRODUCT_STOCK_FAILURE,
  // REFRESH_PRODUCT_STOCK_REQUEST,
  REFRESH_PRODUCT_STOCK_SUCCESS,
  // REFRESH_PRODUCT_STOCK_FAILURE,
  CHANGE_PRODUCT_QUANTITY,
  CHANGE_PRODUCT_DISCOUNT,
  TOGGLE_PRODUCT_PRESENTATION,
  INITIALIZE_CART_WITH_DATA,
  REMOVE_CART_PRODUCT,
  RETRIEVE_CART_DATA,
  CLEAR_CART,
  ADD_ORDERED_PRODUCT_TO_CART
} from 'actions';

import moment from 'moment';

import { normalizeCart, calcDiscountPrice } from 'common/utils';

const getNewStateWithPreservedOrder = (state, action, newProdValues) => {
  const ids = Object.keys(state);
  const newState = {};
  ids.forEach(id => {
    newState[id] = id === action.id ? { ...newProdValues } : { ...state[id] };
  });
  return newState;
};

function isOffer() {
  const activity = JSON.parse(localStorage.getItem('TEMP_ACTIVITY'));
  return activity && activity.type === 'NEW_OFFER_ACTIVITY';
}

const initialState = {};

export default function app(state = initialState, action) {
  switch (action.type) {
    case ADD_CART_PRODUCT: {
      const p = action.payload;
      return {
        [action.id]: {
          quantity: p.quantity,
          displayName: p.displayName,
          documentId: p.documentId,
          imageUrl: p.imageUrl,
          parentCategories: p.parentCategories,
          productCode: p.productCode,
          productNumber: p.productNumber,
          variantCode: p.variantCode,
          creationDate: moment()
        },
        ...state,
      };
    }

    case PRODUCT_STOCK_SUCCESS: {
      const stock = action.payload[0];
      const { quantity: oldQuantity } = state[stock.variantCode];
      const quantity = oldQuantity || stock.minQuantity;
      let defaultDiscount = stock.discount || 0;
      let discount = defaultDiscount;

      if (isOffer()) {
        defaultDiscount = 0;
      }

      if (discount < defaultDiscount && !isOffer()) {
        discount = defaultDiscount;
      }

      const discountedPrice = +calcDiscountPrice(stock.price, quantity, discount).toFixed(2);

      return {
        ...state,
        [stock.variantCode]: {
          ...state[stock.variantCode],
          quantity,
          discount,
          discountedPrice,
          realDiscount: discount,
          realDiscountedPrice: discountedPrice,
          defaultDiscount,
          confirmedDeliveryDate: stock.confirmedDeliveryDate,
          maxQuantity: stock.maxQuantity,
          minQuantity: stock.minQuantity,
          price: stock.price,
          provisionGroup: stock.provisionGroup,
          step: stock.step,
          stock: stock.stock,
          stockLabel: stock.stockLabel,
          unit: stock.unit,
        },
      };
    }

    case REFRESH_PRODUCT_STOCK_SUCCESS: {
      const ids = Object.keys(state);
      const newState = {};

      ids.forEach(id => {
        const newStock = action.payload.find(o => o.variantCode === id);
        const oldProduct = state[id];        
        if (newStock) {
          let defaultDiscount = newStock.discount || 0;
          const discount = oldProduct.discount < defaultDiscount ? defaultDiscount : oldProduct.discount;
          const discountedPrice = +calcDiscountPrice(newStock.price, oldProduct.quantity, discount).toFixed(2);

          newState[id] = {
            ...oldProduct,
            discount,
            discountedPrice,
            realDiscount: discount,
            realDiscountedPrice: discountedPrice,
            defaultDiscount,
            confirmedDeliveryDate: newStock.confirmedDeliveryDate,
            maxQuantity: newStock.maxQuantity,
            minQuantity: newStock.minQuantity,
            provisionGroup: newStock.provisionGroup,
            step: newStock.step,
            stock: newStock.stock,
            stockLabel: newStock.stockLabel,
          };
        } else {
          newState[id] = { ...oldProduct };
        }
      });
      return newState;
    }

    case REMOVE_CART_PRODUCT: {
      const { [action.id]: removedProd, ...products } = state;
      return {
        ...products,
      };
    }

    case RETRIEVE_CART_DATA:
      return {
        ...action.payload,
      };

    case INITIALIZE_CART_WITH_DATA: {
      const products = action.payload;
      const variantCodes = Object.keys(products);
      const newCart = variantCodes.map(variantCode => ({
        ...products[variantCode],
        realDiscount: products[variantCode].discount,
        realDiscountedPrice: products[variantCode].discountedPrice,
      }));
      return normalizeCart(newCart);
    }

    case CHANGE_PRODUCT_QUANTITY: {
      const { minQuantity, realDiscountedPrice, quantity, step } = state[action.id];

      const newQuantityOrMinimum = action.payload < minQuantity ? minQuantity : action.payload;
      const newQuantity = Math.floor(newQuantityOrMinimum / step) * step;

      const newRealDiscountedPrice =
        (realDiscountedPrice / quantity) * newQuantity;

      const newDiscountedPrice = newRealDiscountedPrice.toFixed(2);

      return getNewStateWithPreservedOrder(state, action, {
        ...state[action.id],
        quantity: newQuantity,
        discountedPrice: +newDiscountedPrice || 0,
        realDiscountedPrice: +newRealDiscountedPrice,
      });
    }

    case CHANGE_PRODUCT_DISCOUNT:
      return getNewStateWithPreservedOrder(state, action, {
        ...state[action.id],
        ...action.payload,
      });

    case ADD_ORDERED_PRODUCT_TO_CART: {
      const p = action.payload;
      return {
        [action.id]: {
          // ADDING
          displayName: p.displayName,
          documentId: p.documentId,
          imageUrl: p.imageUrl,
          parentCategories: p.parentCategories,
          productCode: p.productCode,
          productNumber: p.productNumber,
          variantCode: p.variantCode,
          // STOCK
          quantity: p.quantity,
          discount: p.discount,
          realDiscount: p.discount,
          confirmedDeliveryDate: p.confirmedDeliveryDate,
          maxQuantity: p.maxQuantity,
          minQuantity: p.minQuantity,
          provisionGroup: p.provisionGroup,
          step: p.step,
          stock: p.stock,
          stockLabel: p.stockLabel,
          unit: p.unit,
          creationDate: moment()
        },
        ...state
      };
    }

    case TOGGLE_PRODUCT_PRESENTATION:
      return getNewStateWithPreservedOrder(state, action, {
        ...state[action.id],
        isPresented: !state[action.id].isPresented,
      });

    case CLEAR_CART:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
