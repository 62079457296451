import { push } from 'react-router-redux';
import set from 'lodash/set';
import { toast } from 'react-toastify';

import { ACCOUNT_LOGIN_FAILURE, ACCOUNT_LOGOUT_FAILURE, logoutAfterRedirect } from 'actions';
import Api, { API_APP, CALL_API } from 'common/Api';

const queue = {};

// const delay = (time = 0) => result =>
//   new Promise(resolve => {
//     setTimeout(() => {
//       resolve(result);
//     }, time);
//   });

export default store => {
  const apiInstance = new Api(store);

  return next => action => {
    const apiCall = action[CALL_API];
    if (typeof apiCall === 'undefined') {
      return next(action);
    }

    let {
      api = API_APP,
      path,
      types,
      data,
      throwable,
      method = 'get',
      options = {},
      ...apiRest
    } = apiCall;

    if (typeof path === 'function') {
      path = path(store.getState());
    } else if (typeof path !== 'string') {
      throw new Error('No endpoint URL provided.');
    }

    const actionWith = data => {
      const newAction = Object.assign({}, action, data);
      delete newAction[CALL_API];
      return newAction;
    };

    const [requestType, successType, failureType] = types;
    next(actionWith({ type: requestType }));

    const apiMethod = apiInstance[api];

    const tokenSource = apiInstance.getCancelTokenSource();
    const xhr = apiMethod(path, {
      ...options,
      data,
      method,
      cancelToken: tokenSource.token,
    });

    if (!queue[requestType]) {
      queue[requestType] = [];
    }

    const timestamp = Date.now();

    queue[requestType].filter(req => req.timestamp < timestamp).forEach(req => {
      req.tokenSource.cancel();
      req.isCanceled = true;
    });

    queue[requestType] = queue[requestType].filter(
      req => req.isDone || req.isCanceled
    );

    const reqIdx =
      queue[requestType].push({
        timestamp,
        tokenSource,
        isDone: false,
        isCanceled: false,
      }) - 1;

    return xhr
      .then(result => {
        return next(
          actionWith({
            payload: result.data,
            invokedWith: data,
            type: successType,
            ...apiRest,
          })
        );
      })
      .catch(reason => {
        queue[requestType][reqIdx].isDone = true;

        let error =
          (reason.response && reason.response.data) || 'Noe gikk galt';

        next(
          actionWith({
            type: failureType,
            error,
            ...apiRest,
          })
        );

        if (
          reason.response &&
          reason.response.status === 401 &&
          failureType !== ACCOUNT_LOGIN_FAILURE &&
          failureType !== ACCOUNT_LOGOUT_FAILURE
        ) {
          next(logoutAfterRedirect())
          return next(push('/login'));
        }
        if (throwable) {
          const fieldErrs = {};
          error.errors &&
            error.errors.forEach(o => set(fieldErrs, o.field, o.message));
          throw new throwable({
            ...fieldErrs,
            _error: error.message,
          });
        } else {
          const status = reason.response && reason.response.status;
          if(status) {
            toast.error(error)
          } else if(!apiInstance.isCancel(reason)){
            toast.warning('Network error')
          }
          return Promise.reject(error);
        }
      });
  };
};
