import {
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  PRODUCTS_FAILURE,
  PRODUCTS_MORE_REQUEST,
  PRODUCTS_MORE_SUCCESS,
  PRODUCTS_MORE_FAILURE,
  PRODUCTS_STOCK_SUCCESS,
  PRODUCTS_CLEAR_STORAGE,
  CHANGE_ORDERED_PRODUCT_QUANTITY,
} from 'actions';
import { normalizeCart } from 'common/utils';

const initialState = {
  data: {},
  from: 0,
  size: 5,
  isMore: false,
  total: null,
  error: null,
  isFetching: false,
};

export default function app(namespace) {
  return function app(state = initialState, action) {
    switch (action.type) {
      case `${namespace}/${PRODUCTS_REQUEST}`:
      case `${namespace}/${PRODUCTS_MORE_REQUEST}`:
        return {
          ...state,
          isFetching: true,
        };

      case `${namespace}/${PRODUCTS_SUCCESS}`:
      case `${namespace}/${PRODUCTS_MORE_SUCCESS}`: {
        const { from, entries, size, total } = action.payload;
        const isMore = from + size < total;
        const data = normalizeCart(entries);
        return {
          ...state,
          isFetching: false,
          from,
          total,
          isMore,
          data: { ...state.data, ...data },
        };
      }
      case `${namespace}/${PRODUCTS_FAILURE}`:
      case `${namespace}/${PRODUCTS_MORE_FAILURE}`:
        return {
          ...state,
          isFetching: false,
          error: action.error,
        };
      case `${namespace}/${CHANGE_ORDERED_PRODUCT_QUANTITY}`: {
        const product = state.data[action.id];
        const quantity = action.payload;
        return {
          ...state,
          data: {
            ...state.data,
            [action.id]: {
              ...product,
              quantity,
              discountedPrice:
                (quantity * product.price * (100 - product.discount)) / 100,
            },
          },
        };
      }
      case `${namespace}/${PRODUCTS_STOCK_SUCCESS}`:
        return {
          ...state,
          data: action.payload.reduce(
            (obj, val) => ({
              ...obj,
              [val.variantCode]: {
                ...obj[val.variantCode],
                confirmedDeliveryDate: val.confirmedDeliveryDate,
                maxQuantity: val.maxQuantity,
                minQuantity: val.minQuantity,
                provisionGroup: val.provisionGroup,
                step: val.step,
                stock: val.stock,
                stockLabel: val.stockLabel,
                unit: val.unit,
              },
            }),
            state.data
          ),
        };

      case `${namespace}/${PRODUCTS_CLEAR_STORAGE}`:
        return initialState;

      default:
        return state;
    }
  };
}
