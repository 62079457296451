import { change, initialize, SubmissionError } from 'redux-form';
import moment from 'moment';

import { CALL_API } from 'common/Api';
import { getContactPersonId } from 'common/utils';
import { setActivityType } from './activity';

export const NEW_VISIT_FORM = 'NewVisitForm';
export const NEW_VISIT_ACTIVITY = 'NEW_VISIT_ACTIVITY';

export const ADD_VISIT_REQUEST = 'ADD_VISIT_REQUEST';
export const ADD_VISIT_SUCCESS = 'ADD_VISIT_SUCCESS';
export const ADD_VISIT_FAILURE = 'ADD_VISIT_FAILURE';

const addVisitAction = data => ({
  [CALL_API]: {
    types: [ADD_VISIT_REQUEST, ADD_VISIT_SUCCESS, ADD_VISIT_FAILURE],
    path: 'visits-api/Visit',
    method: 'post',
    throwable: SubmissionError,
    data,
  },
});

export const addVisit = data => dispatch => {
  return dispatch(addVisitAction(data));
};

export const setActivityAsNewVisit = () => dispatch =>
  dispatch(setActivityType(NEW_VISIT_ACTIVITY));

const getCorrectContactId = (customer, contactId) =>
  customer.contacts.includes(c => c.primaryKeyId === contactId)
    ? contactId
    : getContactPersonId(customer);

export const initializeNewVisitForm = contactId => (dispatch, getStore) => {
  const { customer, formValues } = getStore().activity;

  const { contactId: prevContactId, ...restFormValues } = formValues || {
    ...formValues,
    contactId,
  };

  const todaysDate = moment().toISOString();
  const formData = {
    contactId: getCorrectContactId(customer, prevContactId),
    startDate: todaysDate,
    endDate: todaysDate,
    ...restFormValues,
  };
  dispatch(initialize(NEW_VISIT_FORM, formData));
};

export const updateNewVisitFormCustomer = () => (dispatch, getStore) => {
  const { customer } = getStore().activity;
  const { form } = getStore();

  const { contactId } = form[NEW_VISIT_FORM].values || {};

  const correctContactId = getCorrectContactId(customer, contactId);

  dispatch(change(NEW_VISIT_FORM, 'contactId', correctContactId));
};
