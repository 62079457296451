export const HOME = 'HOME';
export const CATALOG = 'CATALOG';
export const DASHBOARD = 'DASHBOARD';
export const LOGIN = 'LOGIN';
export const PRODUCTS = 'PRODUCTS';
export const PRODUCT = 'PRODUCT';
export const NEW_CUSTOMER = 'NEW_CUSTOMER';
export const CUSTOMER = 'CUSTOMER';
export const CUSTOMERS = 'CUSTOMERS';
export const ORDERS_OFFERS = 'ORDERS_OFFERS';
export const ORDER = 'ORDER';
export const NEW_ORDER = 'NEW_ORDER';
export const OFFER = 'OFFER';
export const NEW_OFFER = 'NEW_OFFER';
export const VISITS = 'VISITS';
export const VISIT = 'VISIT';
export const NEW_VISIT = 'NEW_VISIT';
