import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components/macro';
import { media } from 'common/theme';

const fadeInOut = css`
  animation: ${keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.65;
    }
    100% {
      opacity: 1;
    }
`} 1.5s infinite linear;
`;

const Button = styled.button`
  ${p => p.theme.size[p.size]};
  border: ${p => (p.nature === 'ghost' ? '2px solid' : 'none')};
  border-radius: 2px;
  padding: 0 12px;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 700;
  height: 45px;
  box-sizing: border-box;
  text-transform: uppercase;
  color: ${p => p.natures[p.nature].color};
  background: ${p => p.natures[p.nature].background};
  margin-bottom: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  transition: background 0.2s;

  ${media.mobile`
    font-size: 16px;
  `};

  &:hover,
  &:focus {
    border-color: ${p =>
      p.nature === 'ghost'
        ? p.disabled
          ? p.theme.grey
          : p.theme.secondary
        : 'none'};
    background: ${p =>
      p.nature === 'ghost'
        ? 'none'
        : p.disabled
          ? p.natures[p.nature].backgroundDisabled(p)
          : p.natures[p.nature].backgroundActive(p)};
  }

  ${p => p.disabled && p.nature === 'ghost' && `border-color: ${p.theme.grey}`};
  ${p =>
    p.disabled &&
    `
      background: ${p.natures[p.nature].backgroundDisabled(p)};
      color: ${p.natures[p.nature].colorDisabled(p)}
      cursor: not-allowed;
    `};

  ${p => p.loading && fadeInOut};
  ${p =>
    p.loading &&
    `
      position: relative;
      overflow: hidden;
  `};
`;

Button.defaultProps = {
  size: 'oneThird',
  nature: 'primary',
  type: 'button',
  natures: {
    primary: {
      color: p => p.theme.light,
      colorDisabled: p => p.theme.light,
      background: p => p.theme.primary,
      backgroundActive: p => p.theme.primaryActive,
      backgroundDisabled: p => p.theme.primaryDisabled,
    },
    secondary: {
      color: p => p.theme.light,
      colorDisabled: p => p.theme.light,
      background: p => p.theme.secondary,
      backgroundActive: p => p.theme.secondaryActive,
      backgroundDisabled: p => p.theme.secondaryDisabled,
    },
    ghost: {
      color: p => p.theme.dark,
      colorDisabled: p => p.theme.grey,
      background: 'none',
      backgroundActive: 'none',
      backgroundDisabled: () => 'none',
    },
  },
};

Button.propTypes = {
  disabled: PropTypes.bool,
  nature: PropTypes.oneOf(['primary', 'secondary', 'ghost']),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['sm', 'oneThird', 'half', 'twoThird', 'lg', 'auto']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

export default Button;
