import { push, replace } from 'react-router-redux';
import {
  getRoute,
  HOME,
  CATALOG,
  PRODUCT,
  CUSTOMER,
  CUSTOMERS,
  OFFER,
  NEW_OFFER,
  ORDER,
  NEW_ORDER,
  ORDERS_OFFERS,
  VISIT,
  NEW_VISIT,
  VISITS,
} from 'routing';

export const OPEN_PRODUCT_DETAILS = 'OPEN_PRODUCT_DETAILS';

export const navigateHome = () => dispatch => {
  return dispatch(push(getRoute(HOME).path));
};

export const navigateProductDetails = (params, isReplace) => dispatch => {
  const method = isReplace ? replace : push;
  const action = method(getRoute(PRODUCT, params).path);
  dispatch(action);
};

export const navigateCustomers = isReplace => dispatch => {
  const method = isReplace ? replace : push;
  const action = method(getRoute(CUSTOMERS).path);
  dispatch(action);
};

export const navigateCustomerDetails = (params, isReplace) => dispatch => {
  const method = isReplace ? replace : push;
  const action = method(getRoute(CUSTOMER, params).path);
  dispatch(action);
};

export const navigateOrdersOffers = isReplace => dispatch => {
  const method = isReplace ? replace : push;
  const action = method(getRoute(ORDERS_OFFERS).path);
  dispatch(action);
};

export const navigateOrderDetails = (params, isReplace) => dispatch => {
  const method = isReplace ? replace : push;
  const action = method(getRoute(ORDER, params).path);
  dispatch(action);
};

export const navigateNewOrder = isReplace => dispatch => {
  const method = isReplace ? replace : push;
  const action = method(getRoute(NEW_ORDER).path);
  dispatch(action);
};

export const navigateNewOffer = isReplace => dispatch => {
  const method = isReplace ? replace : push;
  const action = method(getRoute(NEW_OFFER).path);
  dispatch(action);
};

export const navigateOfferDetails = (params, isReplace) => dispatch => {
  const method = isReplace ? replace : push;
  const action = method(getRoute(OFFER, params).path);
  dispatch(action);
};

export const navigateVisitDetails = (params, isReplace) => dispatch => {
  const method = isReplace ? replace : push;
  const action = method(getRoute(VISIT, params).path);
  dispatch(action);
};

export const navigateVisits = isReplace => dispatch => {
  const method = isReplace ? replace : push;
  const action = method(getRoute(VISITS).path);
  dispatch(action);
};

export const navigateNewVisit = isReplace => dispatch => {
  const method = isReplace ? replace : push;
  const action = method(getRoute(NEW_VISIT).path);
  dispatch(action);
};

export const navigateCategory = (categoryCode, isReplace) => dispatch => {
  const method = isReplace ? replace : push;
  const action = method(getRoute(CATALOG, [categoryCode]).path, {
    noScroll: true,
  });
  dispatch(action);
};
