import { change, initialize, SubmissionError } from 'redux-form';
import moment from 'moment';

import { CALL_API } from 'common/Api';
import {
  getContactPersonId,
  getShippingAddressId,
  getShippingAddressOptions,
  getDateStr,
} from 'common/utils';
import { setActivityType } from './activity';

export const NEW_OFFER_FORM = 'NewOfferForm';
export const NEW_OFFER_ACTIVITY = 'NEW_OFFER_ACTIVITY';

export const ADD_OFFER_REQUEST = 'ADD_OFFER_REQUEST';
export const ADD_OFFER_SUCCESS = 'ADD_OFFER_SUCCESS';
export const ADD_OFFER_FAILURE = 'ADD_OFFER_FAILURE';

const addOfferAction = data => ({
  [CALL_API]: {
    types: [ADD_OFFER_REQUEST, ADD_OFFER_SUCCESS, ADD_OFFER_FAILURE],
    path: 'offers-api/Offer',
    method: 'post',
    throwable: SubmissionError,
    data,
  },
});

export const addOffer = data => dispatch => {
  return dispatch(addOfferAction(data));
};

export const setActivityAsNewOffer = () => dispatch =>
  dispatch(setActivityType(NEW_OFFER_ACTIVITY));

const getCorrectContactId = (customer, contactId) =>
  customer.contacts.includes(c => c.primaryKeyId === contactId)
    ? contactId
    : getContactPersonId(customer);

const getCorrectShippingAddressId = (customer, shippingAddressId) =>
  getShippingAddressOptions(customer).includes(
    a => a.primaryKeyId === shippingAddressId
  )
    ? shippingAddressId
    : getShippingAddressId(customer);

export const initializeNewOfferForm = (contactId, shippingAddressId) => (
  dispatch,
  getStore
) => {
  const { customer, formValues } = getStore().activity;

  const {
    contactId: prevContactId,
    shippingAddressId: prevAddressId,
    ...restFormValues
  } = formValues || { ...formValues, contactId, shippingAddressId };

  const startDate = moment().toISOString();
  const endDate = moment()
    .add(3, 'weeks')
    .toISOString();
  const formData = {
    contactId: getCorrectContactId(customer, prevContactId),
    shippingAddressId: getCorrectShippingAddressId(customer, prevAddressId),
    startDate,
    endDate,
    name: `${customer.name} tilbud ${getDateStr(moment())}`,
    ...restFormValues,
  };
  dispatch(initialize(NEW_OFFER_FORM, formData));
};

export const updateNewOfferFormCustomer = () => (dispatch, getStore) => {
  const { customer } = getStore().activity;
  const { form } = getStore();

  const { contactId, shippingAddressId } = form[NEW_OFFER_FORM].values || {};

  const correctContactId = getCorrectContactId(customer, contactId);

  const correctShippingAddressId = getCorrectShippingAddressId(
    customer,
    shippingAddressId
  );

  dispatch(change(NEW_OFFER_FORM, 'contactId', correctContactId));
  dispatch(
    change(NEW_OFFER_FORM, 'shippingAddressId', correctShippingAddressId)
  );
};
