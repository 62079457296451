import {
  LIST_CUSTOMERS,
  LIST_CUSTOMERS_DASHBOARD,
  LIST_OFFERS,
  LIST_OFFERS_DASHBOARD,
  LIST_ORDERS,
  LIST_ORDERS_DASHBOARD,
  LIST_PRODUCTS,
  LIST_TODAYS_VISITS,
  LIST_VISITS,
} from 'actions';

export const listStores = {
  customers: LIST_CUSTOMERS,
  customersDashboard: LIST_CUSTOMERS_DASHBOARD,
  offers: LIST_OFFERS,
  offersDashboard: LIST_OFFERS_DASHBOARD,
  orders: LIST_ORDERS,
  ordersDashboard: LIST_ORDERS_DASHBOARD,
  products: LIST_PRODUCTS,
  todaysVisits: LIST_TODAYS_VISITS,
  visits: LIST_VISITS,
};

export const listSizes = {
  customers: 10,
  customersDashboard: 2,
  offers: 5,
  offersDashboard: 2,
  orders: 5,
  ordersDashboard: 2,
  products: 6,
  todaysVisits: 1000,
  visits: 10,
};

const initialState = Object.keys(listStores).reduce((state, container) => {
  state[container] = {
    data: [],
    from: 0,
    size: listSizes[container],
    isMore: false,
    total: null,
    error: null,
    isFetching: false,
    category: null,
    level1Code: null,
    level2Code: null,
  };
  return state;
}, {});

const listHandlers = Object.keys(listStores).reduce((handlers, subStore) => {
  handlers[`${listStores[subStore]}_REQUEST`] = state => ({
    ...state,
    [subStore]: {
      ...initialState[subStore],
      isFetching: true,
    },
  });

  handlers[`${listStores[subStore]}_MORE_REQUEST`] = state => ({
    ...state,
    [subStore]: {
      ...state[subStore],
      isFetching: true,
    },
  });

  const success = (state, action) => {
    const { category, from, entries, size, total } = action.payload;

    const isMore = from + size < total;
    const data =
      from === 0 ? [...entries] : [...state[subStore].data, ...entries];

    const categoryData = category
      ? {
          category,
          level1Code: category.parent.code,
          level2Code: category.code,
        }
      : {};

    return {
      ...state,
      [subStore]: {
        ...state[subStore],
        isFetching: false,
        from,
        total,
        data,
        isMore,
        ...categoryData,
      },
    };
  };

  handlers[`${listStores[subStore]}_SUCCESS`] = success;
  handlers[`${listStores[subStore]}_MORE_SUCCESS`] = success;

  const failure = (state, action) => ({
    ...state,
    [subStore]: {
      ...state[subStore],
      isFetching: false,
      error: action.error,
    },
  });

  handlers[`${listStores[subStore]}_FAILURE`] = failure;
  handlers[`${listStores[subStore]}_MORE_FAILURE`] = failure;

  return handlers;
}, {});

export default function app(state = initialState, action) {
  return listHandlers[action.type]
    ? listHandlers[action.type](state, action)
    : state;
}
