import {
  CATALOG,
  CUSTOMER,
  CUSTOMERS,
  DASHBOARD,
  HOME,
  LOGIN,
  NEW_CUSTOMER,
  NEW_OFFER,
  NEW_ORDER,
  NEW_VISIT,
  OFFER,
  ORDER,
  ORDERS_OFFERS,
  PRODUCT,
  PRODUCTS,
  VISIT,
  VISITS,
} from './componentMap';

import { getDateStr } from 'common/utils';

const routes = [
  {
    path: '/login',
    type: LOGIN,
    exact: true,
    noBreadcrumb: true,
  },
  {
    path: '/',
    type: HOME,
    auth: true,
    noBreadcrumb: true,
    routes: [
      {
        path: '/produkter',
        type: CATALOG,
        exact: true,
        crumbs: [{ name: 'Produkter', path: '/produkter' }],
      },
      {
        path: '/produkter/:categoryLevel1?',
        type: CATALOG,
        exact: true,
        crumbs: [{ name: 'Produkter', path: '/produkter' }, getCrumbCategory],
      },
      {
        path:
          '/produkter/:categoryLevel1/:categoryLevel2/:productCode/:variantCode?',
        exact: true,
        type: PRODUCT,
        crumbs: [
          { name: 'Produkter', path: '/produkter' },
          getCrumbCategoryFromVariantLevel1,
          getCrumbCategoryFromVariantLevel2,
          getCrumbCategoryFromProduct,
          getCrumbCategoryFromVariant,
        ],
      },
      {
        path: '/produkter/:categoryLevel1/:categoryLevel2',
        exact: true,
        type: PRODUCTS,
        crumbs: [
          { name: 'Produkter', path: '/produkter' },
          getCrumbCategoryLevel1,
          getCrumbCategoryLevel2,
        ],
      },
      {
        path: '/new-customer',
        exact: true,
        type: NEW_CUSTOMER,
        crumbs: [
          { name: 'Kunder', path: '/customers' },
          { name: 'OPPRETT NY KUNDE', path: '/new-customer' },
        ],
      },
      {
        path: '/customer/:customerId',
        exact: true,
        type: CUSTOMER,
        crumbs: [{ name: 'Kunder', path: '/customers' }, getCrumbCustomer],
      },
      {
        path: '/customers',
        exact: true,
        type: CUSTOMERS,
        crumbs: [{ name: 'Kunder', path: '/customers' }],
      },
      {
        path: '/',
        exact: true,
        type: DASHBOARD,
      },
      {
        path: '/orders-offers',
        exact: true,
        type: ORDERS_OFFERS,
        crumbs: [{ name: 'ordre og tilbud', path: '/orders-offers' }],
      },
      {
        path: '/new-order',
        exact: true,
        type: NEW_ORDER,
        crumbs: [
          { name: 'ordre og tilbud', path: '/orders-offers' },
          { name: 'LAG NY ORDRE', path: '/new-order' },
        ],
      },
      {
        path: '/order/:orderId',
        exact: true,
        type: ORDER,
        crumbs: [
          { name: 'ordre og tilbud', path: '/orders-offers' },
          getCrumbOrder,
        ],
      },
      {
        path: '/new-offer',
        exact: true,
        type: NEW_OFFER,
        crumbs: [
          { name: 'ordre og tilbud', path: '/orders-offers' },
          { name: 'LAG NYTT TILBUD', path: '/new-offer' },
        ],
      },
      {
        path: '/offer/:offerId',
        exact: true,
        type: OFFER,
        crumbs: [
          { name: 'ordre og tilbud', path: '/orders-offers' },
          getCrumbOffer,
        ],
      },
      {
        path: '/visit/:visitId',
        exact: true,
        type: VISIT,
        crumbs: [{ name: 'PLANLEGGER', path: '/visits' }, getCrumbVisit],
      },
      {
        path: '/visits',
        exact: true,
        type: VISITS,
        crumbs: [{ name: 'PLANLEGGER', path: '/visits' }],
      },
      {
        path: '/new-visit',
        exact: true,
        type: NEW_VISIT,
        crumbs: [
          { name: 'PLANLEGGER', path: '/visits' },
          { name: 'NYTT BESØK', path: '/new-visit' },
        ],
      },
    ],
  },
];

const paramsRegex = /:[^\s\/]+/gi; // eslint-disable-line no-useless-escape

const numberOfParams = str => [...(str.match(paramsRegex) || [])].length;

const paramsMatching = (path, params = {}) => {
  const paramKeys = Object.keys(params);
  const matches = path.match(paramsRegex);
  const paramRoute = (matches || []).map(p => p.replace(':', ''));

  return paramKeys.reduce((acc, curr) => {
    return !!~paramRoute.indexOf(curr) || acc;
  }, false);
};

const getRoutes = (type, params, searchRoutes) => {
  const routesToCheck = searchRoutes || routes;
  const paramsLength = params ? params.length : 0;

  let paths = [];

  routesToCheck.forEach(r => {
    const isOptional = /:.*\?/.test(r.path);

    if (r.type === type) {
      const absolutePath = r.path
        .split('/')
        .filter(p => !/\?/.test(p))
        .join('/');

      if (
        paramsLength === numberOfParams(r.path) ||
        paramsMatching(r.path, params)
      ) {
        paths.push({ ...r, path: r.path });
      } else if (
        isOptional &&
        (paramsLength === numberOfParams(absolutePath) ||
          paramsMatching(absolutePath, params))
      ) {
        paths.push({ ...r, path: absolutePath });
      }
    }

    if (r.routes) {
      const innerLinks = getRoutes(type, params, r.routes);
      paths = [...paths, ...innerLinks];
    }
  });

  return paths;
};

export const getRoute = (type, inputParams) => {
  const params = Array.isArray(inputParams)
    ? inputParams.filter(p => !!p)
    : inputParams;
  const routes = getRoutes(type, params);

  if (routes.length) {
    let route = routes[0];
    let path = route.path;

    if (params) {
      if (Array.isArray(params)) {
        params.forEach(param => {
          path = path.replace(/:[^\s\/]+/i, param); // eslint-disable-line no-useless-escape
        });
      } else {
        Object.keys(params).forEach(p => {
          path = path.replace(new RegExp(`:${p}`, 'i'), params[p]);
        });
      }
    }

    return { ...route, path };
  } else {
    return null;
  }
};

const loadingCrumb = { name: '...', path: '/' };

function getCrumbCategory(state, match) {
  const category = Object.values(state.categories.items).find(
    c => c.code === match.params.categoryLevel1
  );
  return category
    ? { name: category.name, path: `/produkter/${category.code}` }
    : loadingCrumb;
}

function getCrumbCategoryFromVariantLevel1(state) {
  if (state.product && Object.keys(state.product.data).length) {
    return {
      name: state.product.data.parentCategories[0].name,
      path: `/produkter/${state.product.data.parentCategories[0].code}`,
    };
  }
  return loadingCrumb;
}

function getCrumbCategoryFromVariantLevel2(state) {
  if (state.product && Object.keys(state.product.data).length) {
    return {
      name: state.product.data.parentCategories[1].name,
      path: `/produkter/${state.product.data.parentCategories[0].code}/${
        state.product.data.parentCategories[1].code
      }`,
    };
  }
  return loadingCrumb;
}

function getCrumbCategoryFromVariant(state, match) {
  if (
    match.params.variantCode &&
    state.product &&
    Object.keys(state.product.data).length &&
    state.product.data.variants
  ) {
    const variant = state.product.data.variants.find(
      v => v.code === match.params.variantCode
    );
    return variant
      ? {
          name: variant.displayName,
          path: `/produkter/${state.product.data.parentCategories[0].code}/${
            state.product.data.parentCategories[1].code
          }`,
          onlyDesktop: true,
        }
      : loadingCrumb;
  }
  return null;
}

function getCrumbCategoryFromProduct(state) {
  if (state.product && Object.keys(state.product.data).length) {
    return {
      name: state.product.data.displayName,
      path: `/produkter/${state.product.data.parentCategories[0].code}/${
        state.product.data.parentCategories[1].code
      }/${state.product.data.code}`,
    };
  }
  return loadingCrumb;
}

function getCrumbCategoryLevel1(state) {
  if (
    state.list.products.category &&
    Object.keys(state.list.products.category).length
  ) {
    return {
      name: state.list.products.category.parent.name,
      path: `/produkter/${state.list.products.category.parent.code}`,
    };
  } else {
    return loadingCrumb;
  }
}

function getCrumbCategoryLevel2(state) {
  if (
    state.list.products.category &&
    Object.keys(state.list.products.category).length
  ) {
    return {
      name: state.list.products.category.name,
      path: `/produkter/${state.list.products.category.parent.code}/${
        state.list.products.category.code
      }`,
    };
  }

  return loadingCrumb;
}

function getCrumbCustomer({ customer }) {
  if (Object.keys(customer.data).length) {
    return {
      name: customer.data.name,
      path: `/customer/${customer.data.documentId}`,
    };
  }

  return loadingCrumb;
}

function getCrumbOrder({ order }) {
  if (Object.keys(order.data).length) {
    return {
      name: order.data.externalNumber,
      path: `/order/${order.data.orderNumber}`,
    };
  }

  return loadingCrumb;
}

function getCrumbOffer({ offer }) {
  if (Object.keys(offer.data).length) {
    return {
      name: offer.data.name,
      path: `/offer/${offer.data.documentId}`,
    };
  }

  return loadingCrumb;
}

function getCrumbVisit({ visit }) {
  if (Object.keys(visit.data).length) {
    return {
      name: `${visit.data.organizationName} ${getDateStr(
        visit.data.startDate
      )}`,
      path: `/visit/${visit.data.documentId}`,
    };
  }

  return loadingCrumb;
}

export default routes;
