import { CALL_API } from 'common/Api';
import { navigateProductDetails } from './navigate';

export const PRODUCT_REQUEST = 'PRODUCT_REQUEST';
export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';
export const PRODUCT_FAILURE = 'PRODUCT_FAILURE';

const fetchProduct = productCode => ({
  [CALL_API]: {
    types: [PRODUCT_REQUEST, PRODUCT_SUCCESS, PRODUCT_FAILURE],
    path: `products-api/Product/${productCode}`,
  },
});

export const getProduct = productCode => (dispatch, getStore) => {
  const { activity } = getStore();
  const { customerNo } = activity.customer;

  return dispatch(fetchProduct(productCode))
    .then(() => dispatch(fetchProductStock(productCode, customerNo)));
};

export const PRODUCT_PAGE_STOCK_REQUEST = 'PRODUCT_PAGE_STOCK_REQUEST';
export const PRODUCT_PAGE_STOCK_SUCCESS = 'PRODUCT_PAGE_STOCK_SUCCESS';
export const PRODUCT_PAGE_STOCK_FAILURE = 'PRODUCT_PAGE_STOCK_FAILURE';

export const PRODUCT_VARIANT_STOCK_REQUEST = 'PRODUCT_VARIANT_STOCK_REQUEST';
export const PRODUCT_VARIANT_STOCK_SUCCESS = 'PRODUCT_VARIANT_STOCK_SUCCESS';
export const PRODUCT_VARIANT_STOCK_FAILURE = 'PRODUCT_VARIANT_STOCK_FAILURE';

const fetchProductStock = (productCode, customerNo) => ({
  [CALL_API]: {
    types: [
      PRODUCT_PAGE_STOCK_REQUEST,
      PRODUCT_PAGE_STOCK_SUCCESS,
      PRODUCT_PAGE_STOCK_FAILURE,
    ],
    path: `stock-api/Stock/GetByCodes${customerNo ? `/${customerNo}` : ''}`,
    method: 'post',
    data: [productCode],
  },
});

const fetchVariantStock = (variantCode, customerNo) => ({
  [CALL_API]: {
    types: [
      PRODUCT_VARIANT_STOCK_REQUEST,
      PRODUCT_VARIANT_STOCK_SUCCESS,
      PRODUCT_VARIANT_STOCK_FAILURE,
    ],
    path: `stock-api/Stock/GetByCodes${customerNo ? `/${customerNo}` : ''}`,
    method: 'post',
    data: variantCode,
  },
});

export const SET_ACTIVE_VARIANT = 'SET_ACTIVE_VARIANT';

export const setActiveVariant = variant => ({
  type: SET_ACTIVE_VARIANT,
  payload: variant,
});

export const getVariant = variantCode => (dispatch, getStore) => {
  const { product, activity } = getStore();
  const { customerNo } = activity.customer;
  const variant = product.data.variants.find(v => v.code === variantCode);
  if (variant) {
    const categoryCodes = product.data.parentCategories.map(c => c.code);
    dispatch(
      navigateProductDetails(
        [...categoryCodes, product.data.code, variantCode],
        true
      )
    );

    dispatch(setActiveVariant(variant));
    dispatch(fetchVariantStock([variantCode], customerNo));
  }
};

export const CLEAR_PRODUCT_STORAGE = 'CLEAR_PRODUCT_STORAGE';

export const clearProductStorage = () => ({
  type: CLEAR_PRODUCT_STORAGE,
});
