import { change, initialize, SubmissionError } from 'redux-form';

import { CALL_API } from 'common/Api';
import {
  getContactPersonId,
  getShippingAddressId,
  getShippingAddressOptions,
} from 'common/utils';
import { setActivityType } from './activity';

export const NEW_ORDER_FORM = 'NewOrderForm';
export const NEW_ORDER_ACTIVITY = 'NEW_ORDER_ACTIVITY';

export const ADD_ORDER_REQUEST = 'ADD_ORDER_REQUEST';
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS';
export const ADD_ORDER_FAILURE = 'ADD_ORDER_FAILURE';


const OFFER_NAME = 'Offer';

const addOrderAction = data => ({
  [CALL_API]: {
    types: [ADD_ORDER_REQUEST, ADD_ORDER_SUCCESS, ADD_ORDER_FAILURE],
    path: `order-api/Order${data.sourceType === OFFER_NAME ? `/${data.sourceId}` : ''}`,
    method: 'post',
    throwable: SubmissionError,
    data,
  },
});

export const addOrder = data => dispatch => {
  return dispatch(addOrderAction(data));
};

export const setActivityAsNewOrder = () => dispatch =>
  dispatch(setActivityType(NEW_ORDER_ACTIVITY));

const getCorrectContactId = (customer, contactId) =>
  customer.contacts.includes(c => c.primaryKeyId === contactId)
    ? contactId
    : getContactPersonId(customer);

const getCorrectShippingAddressId = (customer, shippingAddressId) =>
  getShippingAddressOptions(customer).includes(
    a => a.primaryKeyId === shippingAddressId
  )
    ? shippingAddressId
    : getShippingAddressId(customer);

export const initializeNewOrderForm = (contactId, shippingAddressId) => (
  dispatch,
  getStore
) => {
  const { customer, formValues } = getStore().activity;

  const {
    contactId: prevContactId,
    shippingAddressId: prevAddressId,
    ...restFormValues
  } = formValues || { ...formValues, contactId, shippingAddressId };

  const formData = {
    contactId: getCorrectContactId(customer, prevContactId),
    shippingAddressId: getCorrectShippingAddressId(customer, prevAddressId),
    showDiscount: true,
    ...restFormValues,
  };
  dispatch(initialize(NEW_ORDER_FORM, formData));
};

export const updateNewOrderFormCustomer = () => (dispatch, getStore) => {
  const { customer } = getStore().activity;
  const { form } = getStore();

  const { contactId, shippingAddressId } = form[NEW_ORDER_FORM].values || {};

  const correctContactId = getCorrectContactId(customer, contactId);

  const correctShippingAddressId = getCorrectShippingAddressId(
    customer,
    shippingAddressId
  );

  dispatch(change(NEW_ORDER_FORM, 'contactId', correctContactId));
  dispatch(
    change(NEW_ORDER_FORM, 'shippingAddressId', correctShippingAddressId)
  );
};
