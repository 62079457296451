import { push } from 'react-router-redux';
import { SubmissionError } from 'redux-form';

import { CALL_API, API_EPI } from 'common/Api';
import { cancelActivity } from './activity';

export const ACCOUNT_LOGIN_REQUEST = 'ACCOUNT_LOGIN_REQUEST';
export const ACCOUNT_LOGIN_SUCCESS = 'ACCOUNT_LOGIN_SUCCESS';
export const ACCOUNT_LOGIN_FAILURE = 'ACCOUNT_LOGIN_FAILURE';

const fetchLogin = ({ username, password, rememberMe, authTokenId }) => ({
  [CALL_API]: {
    api: API_EPI,
    types: [
      ACCOUNT_LOGIN_REQUEST,
      ACCOUNT_LOGIN_SUCCESS,
      ACCOUNT_LOGIN_FAILURE,
    ],
    path: `account/sign-in/${authTokenId || ''}`,
    method: 'post',
    data: authTokenId
      ? null
      : {
          username,
          password,
          rememberMe,
        },
  },
});

const loginSuccess = (result, dispatch) => {
  if (result && result.type === ACCOUNT_LOGIN_SUCCESS) {
    const auth = {
      authToken: result.payload.auth,
      identity: result.payload.identity.name,
      expires: result.payload.expires,
    };
    saveAuthToLocalStorage(auth);
    return dispatch(push('/'));
  } else {
    return Promise.reject('Unknown error');
  }
};

export const login = ({ username, password, rememberMe }) => dispatch =>
  dispatch(fetchLogin({ username, password, rememberMe }))
    .then(result => loginSuccess(result, dispatch))
    .catch(error => {
      throw new SubmissionError({
        _error: error,
      });
    });

export const loginSSO = authTokenId => dispatch => {
  window.history.replaceState({}, 'Relekta', '/');
  dispatch(fetchLogin({ authTokenId })).then(
    result => loginSuccess(result, dispatch),
    err => dispatch(push('/login'))
  );
};

// --- --- --- --- --- ---

export const ACCOUNT_LOGOUT_REQUEST = 'ACCOUNT_LOGOUT_REQUEST';
export const ACCOUNT_LOGOUT_SUCCESS = 'ACCOUNT_LOGOUT_SUCCESS';
export const ACCOUNT_LOGOUT_FAILURE = 'ACCOUNT_LOGOUT_FAILURE';

const fetchLogout = () => ({
  [CALL_API]: {
    api: API_EPI,
    types: [
      ACCOUNT_LOGOUT_REQUEST,
      ACCOUNT_LOGOUT_SUCCESS,
      ACCOUNT_LOGOUT_FAILURE,
    ],
    path: 'account/sign-out',
    method: 'post',
  },
});

export const logout = () => dispatch => {
  localStorage.removeItem('auth');
  dispatch(cancelActivity({ noRedirect: true }));
  return dispatch(fetchLogout()).then(() => dispatch(push('/login')));
};

export const logoutAfterRedirect = () => dispatch => {
  localStorage.removeItem('auth');
  dispatch(cancelActivity({ noRedirect: true }));
  return dispatch({ type: ACCOUNT_LOGOUT_SUCCESS });
};

// --- --- --- --- --- ---

export const ACCOUNT_SET_TOKEN = 'ACCOUNT_SET_TOKEN';

const saveAuthToLocalStorage = auth => {
  localStorage.setItem('auth', JSON.stringify(auth));
};

export const accountSetToken = auth => {
  saveAuthToLocalStorage(auth);

  return {
    type: ACCOUNT_SET_TOKEN,
    payload: auth,
  };
};
