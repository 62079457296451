import React from 'react';

const ConfirmIcon = props => (
  <svg viewBox="0 0 152 152" width="1em" height="1em" {...props}>
    <path
      d="M76 152c-41.974 0-76-34.026-76-76S34.026 0 76 0s76 34.026 76 76-34.026 76-76 76zM63.59 91.716L46.649 74.791 41 80.433 63.59 103 112 54.642 106.352 49 63.591 91.716z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default ConfirmIcon;
