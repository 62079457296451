import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import ConfirmationCard from 'components/ConfirmationCard';
import Modal from 'components/Modal';

class ActionPrompt extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };
    this.close = this.close.bind(this);
    this.doAction = this.doAction.bind(this);
    this.doSecondaryAction = this.doSecondaryAction.bind(this);
    this.open = this.open.bind(this);
  }

  close() {
    this.setState({ open: false });
  }

  doAction() {
    this.props.action();
    this.close();
  }

  doSecondaryAction() {
    if(this.props.secondaryAction){
      this.props.secondaryAction();
    }
    this.close();
  }

  open() {
    this.setState({ open: true });
  }


  render() {
    const { message, primaryBtnLabel, secondaryBtnLabel, title } = this.props;
    return (
      <Fragment>
        <Modal isOpen={this.state.open} keepMounted onCloseClick={this.close}>
          <ConfirmationCard
            message={message}
            nature="confirmation"
            onCloseClick={this.close}
            primaryBtnAction={this.doAction}
            primaryBtnLabel={primaryBtnLabel}
            secondaryBtnAction={this.doSecondaryAction}
            secondaryBtnLabel={secondaryBtnLabel}
            title={title}
          />
        </Modal>
        {this.props.children(this.open)}
      </Fragment>
    );
  }
}

ActionPrompt.propTypes = {
  action: PropTypes.func.isRequired,
  secondaryAction: PropTypes.func,
  message: PropTypes.string,
  primaryBtnLabel: PropTypes.string,
  secondaryBtnLabel: PropTypes.string,
  title: PropTypes.string,
};

ActionPrompt.defaultProps = {
  primaryBtnLabel: 'Ja',
  secondaryBtnLabel: 'Nei',
  title: 'Er du sikker?',
};

export default ActionPrompt;
