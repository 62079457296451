import React from 'react';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Link from 'components/Link';
import { ContentWrapper } from 'components/Layout';
import { media } from 'common/theme';
import { Tablet, Mobile } from 'common/MediaQuery';
import { HomeLink } from 'routing';

const StyledContentWrapper = styled(ContentWrapper)`
  order: -1;
`;

const StyledArrow = styled(KeyboardArrowRight)`
  && {
    margin-left: -10px;
    margin-right: -10px;
    font-size: 36px;
    fill: ${p => p.theme.primary};
    ${p => p.mobile && 'transform: rotate(180deg)'};
  }
`;

const StyledBreadcrumb = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
`;

const StyledList = styled.ul`
  display: flex;
  min-height: 40px;
  margin: 12px 0;
  flex-wrap: wrap;

  ${media.mobile`
    margin: 8px 0;
  `};
`;

const StyledTextContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;

  ${p =>
    p.isLast &&
    `
    border-bottom: solid 3px ${p.theme.secondary};
    padding-top: 8px;
  `};
`;

const StyledText = styled.p`
  font-size: 14px;
  word-break: break-all;
  text-transform: uppercase;
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  text-transform: uppercase;
  color: ${p => p.theme.dark};
  word-break: break-all;
  text-decoration: none;

  &:hover {
    color: ${p => p.theme.primary};
  }

  ${media.mobile`
    font-size: 16px;
  `};
`;

const Breadcrumbs = ({ isLoading = false, items, match, state }) => {
  const renderItems = items
    .map(item => (typeof item === 'function' ? item(state, match) : item))
    .filter(item => !!item);

  const renderMobile = renderItems
    .filter(item => !item.onlyDesktop)
    .slice(-2, -1);

  if (renderItems.length === 0) {
    return (
      <StyledContentWrapper>
        <div style={{ height: 24 }} />
      </StyledContentWrapper>
    );
  }

  return (
    <StyledContentWrapper>
      <StyledList>
        <Mobile max>
          {renderMobile.length > 0 ? (
            renderMobile.map(({ name, path }, index) => (
              <StyledBreadcrumb key={index}>
                <StyledArrow mobile />
                <StyledTextContainer>
                  <StyledLink to={path}>{name}</StyledLink>
                </StyledTextContainer>
              </StyledBreadcrumb>
            ))
          ) : (
            <StyledBreadcrumb>
              <StyledArrow mobile />
              <StyledTextContainer>
                <HomeLink
                  render={({ route }) => (
                    <StyledLink to={route.path}>Dashboard</StyledLink>
                  )}
                />
              </StyledTextContainer>
            </StyledBreadcrumb>
          )}
        </Mobile>

        <Tablet min>
          <StyledBreadcrumb>
            <StyledTextContainer>
              <HomeLink
                render={({ route }) => (
                  <StyledLink to={route.path}>Dashboard</StyledLink>
                )}
              />
            </StyledTextContainer>
          </StyledBreadcrumb>
          {isLoading || renderItems.length === 0
            ? null
            : renderItems.map(({ name, path }, index) => (
                <StyledBreadcrumb key={index}>
                  <StyledArrow />
                  <StyledTextContainer
                    isLast={renderItems.length === index + 1}
                  >
                    {renderItems.length === index + 1 ? (
                      <StyledText>{name}</StyledText>
                    ) : (
                      <StyledLink to={path}>{name}</StyledLink>
                    )}
                  </StyledTextContainer>
                </StyledBreadcrumb>
              ))}
        </Tablet>
      </StyledList>
    </StyledContentWrapper>
  );
};

Breadcrumbs.propTypes = {
  isLoading: PropTypes.bool,
  items: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  state: PropTypes.object,
};

export default Breadcrumbs;
