import {
  CUSTOMER_SUCCESS,
  CUSTOMER_REQUEST,
  CUSTOMER_FAILURE,
  CLEAR_CUSTOMER_STORAGE,
} from 'actions';

export const unpackData = ({ shippingAddresses, contacts, ...rest }) => ({
  ...rest,
  shippingAddresses: shippingAddresses.length ? shippingAddresses : [{}],
  contacts: contacts.length ? contacts : [{}],
  primaryAddressIndex: shippingAddresses.findIndex(
    a => a.primaryAddress === true
  ),
});

const initialState = {
  data: {},
  meta: {},
  error: null,
  isFetching: false,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_REQUEST:
      return {
        ...state,
        data: {},
        isFetching: true,
      };

    case CUSTOMER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: unpackData(action.payload),
      };

    case CUSTOMER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case CLEAR_CUSTOMER_STORAGE:
      return initialState;

    default:
      return state;
  }
}
