import { createGlobalStyle, css } from 'styled-components/macro';

import reset from './reset';

export const breakpoints = {
  desktop: 1024,
  tablet: 768,
  mobile: 480,
};

export const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

const palette = {
  tangerine: '#f39800', // orange
  cobalt: '#1a57a1', //  blue
  white: '#f4f4f4',
  charcoal_grey: '#202020', //'#434748',
  lightgrey: '#cbcbcb', //'#434748',
  crimson: '#cd1226', // red
  lightsteelblue: '#afc4de', // blue disabled
  midnightblue: '#103f79', // blue hover
  navajowhite: '#fbdba6', // orange disabled
  darkorange: '#f37300', // orange hover
  turquoise: '#31efd4', // success
};

export const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

  ${reset};

  html, body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    color: ${palette.charcoal_grey};
    height: 100vh;
    width: 100%;
  }

  #root {
    width: 100%;
    min-height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    display: flex;
  }
`;

export const theme = {
  primary: palette.cobalt,
  primaryActive: palette.midnightblue,
  primaryDisabled: palette.lightsteelblue,

  secondary: palette.tangerine,
  secondaryActive: palette.darkorange,
  secondaryDisabled: palette.navajowhite,

  light: palette.white,
  grey: palette.lightgrey,
  dark: palette.charcoal_grey,
  success: palette.turquoise,
  error: palette.crimson,
  size: {
    sm: 'width: 111px',
    oneThird: 'width: 234px',
    half: 'width: 100%; max-width: 358px',
    twoThird: 'width: 100%; max-width: 480px',
    lg: 'width: 400px',
    auto: 'width: 100%',
  },
};
