import { SubmissionError } from 'redux-form';

import { CALL_API } from 'common/Api';

export const CUSTOMER_REQUEST = 'CUSTOMER_REQUEST';
export const CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS';
export const CUSTOMER_FAILURE = 'CUSTOMER_FAILURE';

const fetchCustomer = customerId => ({
  [CALL_API]: {
    types: [CUSTOMER_REQUEST, CUSTOMER_SUCCESS, CUSTOMER_FAILURE],
    path: `customers-api/Customer/${customerId}`,
  },
});

export const getCustomer = customerId => dispatch => {
  return dispatch(fetchCustomer(customerId));
};

export const EDIT_CUSTOMER_REQUEST = 'EDIT_CUSTOMER_REQUEST';
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER_FAILURE = 'EDIT_CUSTOMER_FAILURE';

const fetchEditCustomer = data => ({
  [CALL_API]: {
    types: [
      EDIT_CUSTOMER_REQUEST,
      EDIT_CUSTOMER_SUCCESS,
      EDIT_CUSTOMER_FAILURE,
    ],
    path: 'customers-api/Customer',
    method: 'put',
    throwable: SubmissionError,
    data,
  },
});

const packData = ({ primaryAddressIndex, shippingAddresses, ...data }) => ({
  ...data,
  shippingAddresses: Object.keys(shippingAddresses[0]).length
    ? [
        ...shippingAddresses.map((a, index) => ({
          ...a,
          primaryAddress: index === primaryAddressIndex,
        })),
      ]
    : [],
});

export const editCustomer = data => dispatch => {
  return dispatch(fetchEditCustomer(packData(data)));
};

export const ADD_CUSTOMER_REQUEST = 'ADD_CUSTOMER_REQUEST';
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_FAILURE = 'ADD_CUSTOMER_FAILURE';

const fetchAddCustomer = data => ({
  [CALL_API]: {
    types: [ADD_CUSTOMER_REQUEST, ADD_CUSTOMER_SUCCESS, ADD_CUSTOMER_FAILURE],
    path: 'customers-api/Customer',
    method: 'post',
    throwable: SubmissionError,
    data,
  },
});

export const addCustomer = data => dispatch => {
  return dispatch(fetchAddCustomer(packData(data)));
};

export const CLEAR_CUSTOMER_STORAGE = 'CLEAR_CUSTOMER_STORAGE';

export const clearCustomerStorage = () => ({
  type: CLEAR_CUSTOMER_STORAGE,
});

export const EXTERNAL_CUSTOMER_REQUEST = 'EXTERNAL_CUSTOMER_REQUEST';
export const EXTERNAL_CUSTOMER_SUCCESS = 'EXTERNAL_CUSTOMER_SUCCESS';
export const EXTERNAL_CUSTOMER_FAILURE = 'EXTERNAL_CUSTOMER_FAILURE';

const getExternalCustomerByNumberAction = organizationNo => ({
  [CALL_API]: {
    types: [
      EXTERNAL_CUSTOMER_REQUEST,
      EXTERNAL_CUSTOMER_SUCCESS,
      EXTERNAL_CUSTOMER_FAILURE,
    ],
    path: `customers-api/Externals/GetByNumber/${organizationNo}`,
    throwable: SubmissionError,
  },
});

export const getExternalCustomerByNumber = organizationNo => dispatch => {
  return dispatch(getExternalCustomerByNumberAction(organizationNo));
};
