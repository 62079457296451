import { CALL_API } from 'common/Api';
import debounce from 'lodash/debounce';

const getTypes = prefix =>
  ['REQUEST', 'SUCCESS', 'FAILURE'].map(suffix => [prefix, suffix].join('_'));

export const CLEAR_ITEMS = 'CLEAR_ITEMS';

export const SEARCH_EXTERNAL_CUSTOMER = 'SEARCH_EXTERNAL_CUSTOMER';
export const SEARCH_CUSTOMER = 'SEARCH_CUSTOMER';
export const SEARCH_USER_CUSTOMER = 'SEARCH_USER_CUSTOMER';
export const SEARCH_OFFER = 'SEARCH_OFFER';
export const SEARCH_ORDER = 'SEARCH_ORDER';
export const SEARCH_PRODUCT = 'SEARCH_PRODUCT';
export const SEARCH_VARIANT = 'SEARCH_VARIANT';
export const SEARCH_VISIT = 'SEARCH_VISIT';

const pathMap = {
  [SEARCH_EXTERNAL_CUSTOMER]: 'customers-api/Externals/GetByName',
  [SEARCH_CUSTOMER]: 'customers-api/Customers/QueryActive',
  [SEARCH_USER_CUSTOMER]: 'customers-api/Customers/QueryUserCustomers',
  [SEARCH_OFFER]: 'offers-api/Offers/Query',
  [SEARCH_ORDER]: 'order-api/Orders/Query',
  [SEARCH_PRODUCT]: 'products-api/Products/Query',
  [SEARCH_VARIANT]: 'products-api/Variants/Query',
  [SEARCH_VISIT]: 'visits-api/Visits/Query',
};

const searchItems = type => term => dispatch => {
  const searchTerm = term.trim();
  return searchTerm.length === 0 ||
    searchTerm === '' ||
    typeof searchTerm === 'undefined'
    ? dispatch(clearItems(type)())
    : debouncedSearch(dispatch, type, term);
};

const debouncedSearch = debounce(
  (dispatch, type, term) => dispatch(searchAction(type, term)),
  300
);

const searchAction = (type, term) => ({
  [CALL_API]: {
    types: getTypes(type),
    path: `${pathMap[type]}/${term}`,
    options: {
      params: {
        from: 0,
        size: 200,
      },
    },
  },
});

const clearItems = type => () => ({
  type: `${CLEAR_ITEMS}_${type}`,
});

export const searchExternalCustomer = searchItems(SEARCH_EXTERNAL_CUSTOMER);
export const searchCustomers = searchItems(SEARCH_CUSTOMER);
export const searchUserCustomers = searchItems(SEARCH_USER_CUSTOMER);
export const searchOffers = searchItems(SEARCH_OFFER);
export const searchOrders = searchItems(SEARCH_ORDER);
export const searchProducts = searchItems(SEARCH_PRODUCT);
export const searchVariants = searchItems(SEARCH_VARIANT);
export const searchVisits = searchItems(SEARCH_VISIT);

export const clearSearchExternalCustomer = clearItems(SEARCH_EXTERNAL_CUSTOMER);
export const clearSearchCustomers = clearItems(SEARCH_CUSTOMER);
export const clearSearchUserCustomers = clearItems(SEARCH_USER_CUSTOMER);
export const clearSearchOffers = clearItems(SEARCH_OFFER);
export const clearSearchOrders = clearItems(SEARCH_ORDER);
export const clearSearchProducts = clearItems(SEARCH_PRODUCT);
export const clearSearchVariants = clearItems(SEARCH_VARIANT);
export const clearSearchVisits = clearItems(SEARCH_VISIT);
